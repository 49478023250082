import { Box, SelectChangeEvent, Typography } from "@mui/material";
import { t } from "i18next";
import React from "react";
import AutoComplete from "../../common/input/AutoComplete";
import InputField from "../../common/input/InputField";
import DialogModal from "../../common/modal/DialogModal";
import Button from "../../common/button/Button";
import Grid from "@mui/material/Grid2";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import { CONSTANTS } from "../../../constants/constants";

/**
 * This Component can be used for providing selection for the specialist feature 
 * @param props - Required isForwardModalOpen, setIsForwardModalOpen, setSpecialty, setInputVal, inputVal, setIsChatScreenOpen, specialty, specialityList, errorMessage, setErrorMessage, userRole 
 * @returns Model with selection specialist feature
 */
const SelectSpecialistModal = (props: any) => {
    const { isForwardModalOpen, setIsForwardModalOpen, setSpecialty, setInputVal, inputVal, setIsChatScreenOpen, specialty, specialityList, errorMessage, setErrorMessage, userRole } = props;

    const handleSpecialty = (event: React.SyntheticEvent, newValue: {id: number, specialty: string} | { id: number; specialty: string; }[] | null) => {
        setSpecialty(newValue);
        if (newValue) {
            setErrorMessage(null);
        }
    };


    const handleInput = (event: SelectChangeEvent<string>) => {
        setInputVal(event.target.value);
        if (event.target.value !== "") {
            setErrorMessage(null);
        }
    };

    const handleCloseForwardModal = () => {
        setIsForwardModalOpen(false);
        setSpecialty(null);
        setInputVal("");
        setErrorMessage(null);
    };

    const handleNext = () => {
        if (specialty || inputVal !== "") {
            setIsForwardModalOpen(false);
            setIsChatScreenOpen(true);
            setSpecialty(null);
            setInputVal("");
            setErrorMessage(null);
        } else {
            setErrorMessage("Please select any one of the above");
        }
    };

    return (
        <DialogModal open={isForwardModalOpen} className={"wpx_modal_width"}>
            <Box sx={{ padding: 3 }}>
                <Box>
                    <Box component={"span"}>
                        <ClearOutlinedIcon
                            sx={{ cursor: "pointer", float: "right" }}
                            onClick={handleCloseForwardModal}
                        />
                        <Typography variant="h6">{t("chat_history.choose_speciality_connect")}</Typography>
                    </Box>

                    <AutoComplete
                        label={t("chat_history.speciality")}
                        options={specialityList}
                        getOptionLabel={(option) => option.specialty}
                        value={specialty}
                        disabled={inputVal}
                        onChange={handleSpecialty}
                        placeholder="Search specialist"
                        className="wpx_chat_history_width"
                    />
                    {/* Selection of medical professional depends on the Used Role: Only CSR is supposed to choose the MP */}
                    {
                        userRole === CONSTANTS.CUSTOMER_SUPPORT ?
                            <>
                                <Typography className="wpx_typo_height">{t("common.or")}</Typography>
                                <Typography variant="h6" className="wpx_typo_height">{t("chat_history.choose_medical_professional")}</Typography>
                                <Typography>{t("chat_history.try_search")}</Typography>
                                <InputField
                                    value={inputVal}
                                    placeHolder={t("chat_history.search_mp")}
                                    onChange={handleInput}
                                    disabled={specialty}
                                    className="wpx_chat_history_width" />

                            </>
                            : null
                    }

                    <Typography color="error" sx={{ mt: 2 }}>
                        {errorMessage}
                    </Typography>
                    <Grid container spacing={2}>
                        <Grid size={{ xs: 10, sm: 5, md: 5, lg: 4 }}>
                            <Button
                                className="wpx_next__btn"
                                label={t("common.next")}
                                variant="contained"
                                onClick={handleNext} />
                        </Grid>
                    </Grid>

                </Box>
            </Box>
        </DialogModal>
    );
};

export default SelectSpecialistModal;