import React, { useEffect, useState } from "react";
import { CONSTANTS } from "../../../constants/constants";
import useAxios from "../../../hooks/useAxios";
import Table from "../../../components/common/table/Table";
import Pagination from "../../../components/common/table/Pagination";
import DialogModal from "../../../components/common/modal/DialogModal";
import {
    Box,
    DialogActions,
    DialogContent,
    DialogContentText,
    Stack,
    Typography,
} from "@mui/material";
import Button from "../../../components/common/button/Button";
import { useTranslation } from "react-i18next";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import InputField from "../../../components/common/input/InputField";
import SaveAsIcon from "@mui/icons-material/SaveAs";
import { mockAPIPath } from "../config/apiPath";
import AddIcon from "@mui/icons-material/Add";
import { ROUTES } from "../../../constants/routes";
import AutoComplete from "../../../components/common/input/AutoComplete";
import Skeleton from "../../../components/common/skeleton";
import { commonActions } from "../../../store/features/commonSlice";
import { useAppDispatch } from "../../../store/hooks";

const ManageOrgAdminList = () => {
    const [t] = useTranslation();

    const dispatch = useAppDispatch();

    const [totalCount, setTotalCount] = useState<number>(0);
    const [limit, setLimit] = useState<number>(CONSTANTS.PAGINATION_LIMIT);
    const [currentPage, setCurrentPage] = useState<number>(CONSTANTS.FIRST_PAGE);
    const [orgAdminList, setOrgAdminList] = useState<any>([]);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false);
    const [isAddModalOpen, setIsAddModalOpen] = useState<boolean>(false);
    const [selectedSearchOrg, setSelectedSearchOrg] = useState<any>(null);
    const [skeleton, setSkeleton] = useState<any>(true);
    const [editData, setEditData] = useState<any>(null);
    const [showValidation, setShowValidation] = useState<boolean>(false);

    const orgAdminListApiCall = useAxios({
        url: `${mockAPIPath.ORG_ADMIN_LIST}?page=${currentPage}&limit=${limit}`,
        method: "GET",
    });

    useEffect(() => {
        const { data } = orgAdminListApiCall;
        if (data) {
            setOrgAdminList(data.data);
            if (data.totalCount && data.totalPages) {
                setTotalCount(data.totalCount);
            }
        }
        setSkeleton(false);
    }, [orgAdminListApiCall]);
       
    const columns = [
        { id: "id", label: t("common.s_no"), width: "10%"  },
        { id: "adminName", label: t("manage_org_admin.admin_name"), width: "20%"  },
        { id: "adminEmail", label: t("manage_org_admin.admin_email"), width: "20%"  },
        { id: "organizationName", label: t("manage_org_admin.organization_name"), width: "30%" },
        { id: "actions", label: t("common.action"), width: "20%"  },
    ];

    const searchOrganizationList  = [
        { id: 1, organization: "Fortis1 CDOC, Chirag Enclave, New Delhi" },
        { id: 2, organization: "Fortis2 CDOC, Chirag Enclave, New Delhi" },
        { id: 3, organization: "Fortis3 CDOC, Chirag Enclave, New Delhi"},
    ];

    const handleEdit = (user: any) => {
        setIsEditModalOpen(true);
        setEditData(user);
        setShowValidation(false);
        const tempData = searchOrganizationList.filter((item:any)=>item.organization === user.organizationName);
        setSelectedSearchOrg(tempData);

    };

    const handleNavigate = (user: any) => {
        console.log("edit user:", user);  
        window.open(ROUTES.OA.MANAGE_MP);
    };

    const handleDelete = (user: any) => {
        setIsDeleteModalOpen(true);
        console.log("Delete user:", user);
    };

    const handleFieldChange = (e:any, fieldName: string) => {
        const tempData ={...editData};
        tempData[fieldName] = e.target.value;
        setEditData(tempData);
    };

    const handleAddBtnClick = () => {
        setShowValidation(false);
        setIsAddModalOpen(true);
        setEditData(null);
        setSelectedSearchOrg(null);
    };

    const handleAddOrgAdmin = () => {
        setShowValidation(true);
        console.log("addData", editData, selectedSearchOrg);
        if(editData?.adminName && editData?.adminEmail && selectedSearchOrg?.length > 0){
            dispatch(commonActions.IS_SNACKBAR(true));
            dispatch(commonActions.SNACKBAR_MESSAGE("Organization admin is added successfully"));
            setIsAddModalOpen(false);
        }
    };

    const handleEditOrgAdmin = () => {
        setShowValidation(true);
        console.log("editData", editData, selectedSearchOrg);
        if(editData?.adminName && editData?.adminEmail && selectedSearchOrg?.length>0){
            dispatch(commonActions.IS_SNACKBAR(true));
            dispatch(commonActions.SNACKBAR_MESSAGE("Organization admin is edited successfully"));
            setIsEditModalOpen(false);  
        }    
    };

    const handleModalDeleteButton = () => {
        setIsDeleteModalOpen(false);
        dispatch(commonActions.IS_SNACKBAR(true));
        dispatch(commonActions.SNACKBAR_MESSAGE("Organization admin  is deleted successfully"));
    };

    const handleCloseEditModal = () => {
        setIsEditModalOpen(false);
    };

    const handleModalConfirmAction = () => {
        setIsDeleteModalOpen(false);
    };

    const handlePageChange = (newPage: number, newLimit: number) => {
        setCurrentPage(newPage);
        setLimit(newLimit);
    };

  

    const handleSearchOrganization = (event: React.SyntheticEvent, newValue: any) => {
        setSelectedSearchOrg(newValue);
    };

    const closeAddDataModal = () => {
        setIsAddModalOpen(false);
    };

    return (
        <>
            {skeleton ? (
                <Skeleton
                    variant="rectangular"
                    width={"100%"}
                    height={30}
                    count={15}
                />
            ) : (
                <>
                    <Table
                        data={orgAdminList}
                        columns={columns}
                        onEdit={handleEdit}
                        onDelete={handleDelete}
                        onNavigate={handleNavigate}
                    />
                    <Pagination
                        totalCount={totalCount}
                        currentPage={currentPage}
                        pageChange={handlePageChange}
                        rowsPerPage={limit}
                        rowsPerPageChange={setLimit}
                    />
                </>
            )}  
            <Box className="wpx_modal_btnCenter">
                <Button
                    className="wpx_plus__btn"
                    variant="contained"
                    onClick={handleAddBtnClick}
                    startIcon={<AddIcon />}
                />
            </Box>
            <DialogModal open={isDeleteModalOpen}>
                <Box sx={{ py: 2 }}>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {t("common.delete_confirmation_msg")}
                        </DialogContentText>
                        <DialogContentText id="alert-dialog-description">
                            {t("common.msg_to_proceed")}
                        </DialogContentText>
                    </DialogContent>

                    <Box className="wpx_textCenter">
                        <Button
                            className="wpx_save__btn"
                            label={t("common.yes")}
                            variant="contained"
                            startIcon={<CheckRoundedIcon />}
                            onClick={handleModalDeleteButton}
                        />
                        <Button
                            className="wpx_save__btn"
                            label={t("common.no")}
                            variant="contained"
                            startIcon={<ClearOutlinedIcon />}
                            onClick={handleModalConfirmAction}
                        />
                    </Box>
                </Box>
            </DialogModal>
            <DialogModal open={isEditModalOpen}>
                <DialogActions
                    className="wpx_manage__form__action__btn"
                    sx={{ px: 2, mt: 2, ml:"8px", justifyContent: "space-between"  }}
                >        
                    <Typography sx={{fontWeight: "500", fontSize:"18px"}} >{t("manage_org_admin.edit_org_admin")}</Typography>
                    <ClearOutlinedIcon
                        sx={{ cursor: "pointer" }}
                        onClick={handleCloseEditModal}
                    />
                </DialogActions>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        <Box component="form" className="wpx_input_field_style">
                            <Stack
                                component="form"
                                spacing={2}
                                noValidate
                                autoComplete="off"
                                className=""
                                sx={{ width: "500px" }}
                            >
                                <InputField value={editData?.adminName} onChange = {(e)=>{handleFieldChange(e, "adminName");}} variant="outlined" label="Admin Name" />
                                {
                                    showValidation && !editData?.adminName ? 
                                        <Typography color="error">{t("manage_csr.enter_name")}</Typography>
                                        : null
                                }
                                <InputField value={editData?.adminEmail} onChange = {(e)=>{handleFieldChange(e, "adminEmail");}} variant="outlined" label="Email" />
                                {
                                    showValidation && !editData?.adminEmail ? 
                                        <Typography color="error">{t("manage_csr.enter_email")}</Typography>
                                        : null
                                }
                                <AutoComplete
                                    label="Select Organization"
                                    options={searchOrganizationList}
                                    getOptionLabel={(option) => option.organization}
                                    value={selectedSearchOrg}
                                    onChange={handleSearchOrganization}
                                    placeholder="Search for an organization"
                                    multiple={true}
                                />
                                {
                                    showValidation && !(selectedSearchOrg?.length > 0) ? 
                                        <Typography color="error">{t("manage_org_admin.select_organization")}</Typography>
                                        : null
                                }
                            </Stack>
                            <Box className="wpx_modal_btnCenter">
                                <Button
                                    className="wpx_save__btn"
                                    variant="contained"
                                    startIcon={<SaveAsIcon />}
                                    label={t("common.save")}   
                                    onClick={handleEditOrgAdmin}                                                               
                                />
                            </Box>
                        </Box>
                    </DialogContentText>
                </DialogContent>
            </DialogModal>
            <DialogModal open={isAddModalOpen}>
                <DialogActions
                    className="wpx_manage__form__action__btn"
                    sx={{ px: 2, mt: 2, ml:"31px", justifyContent: "space-between"  }}
                >
                    <Typography sx={{fontWeight: "500", fontSize:"18px"}}>{t("manage_org_admin.add_org_admin")}</Typography>
                    <ClearOutlinedIcon
                        sx={{ cursor: "pointer" }}
                        onClick={closeAddDataModal}
                    />
                </DialogActions>
                <DialogContent>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description">
                            <Box component="form" className="wpx_input_field_style">
                                <Stack
                                    component="form"
                                    spacing={2}
                                    noValidate
                                    autoComplete="off"
                                    sx={{ width: "500px" }}
                                >
                                    <InputField value={editData?.adminName ? editData?.adminName : ""} onChange = {(e)=>{handleFieldChange(e, "adminName");}}  variant="outlined" label="Admin Name" />
                                    {
                                        showValidation && !editData?.adminName ? 
                                            <Typography color="error">{t("manage_csr.enter_name")}</Typography>
                                            : null
                                    }
                                    <InputField value={editData?.adminEmail ? editData?.adminEmail : ""} onChange = {(e)=>{handleFieldChange(e, "adminEmail");}} variant="outlined" label="Email" />
                                    {
                                        showValidation && !editData?.adminEmail ? 
                                            <Typography color="error">{t("manage_csr.enter_email")}</Typography>
                                            : null
                                    }
                                    <AutoComplete
                                        label="Select Organization"
                                        options={searchOrganizationList}
                                        getOptionLabel={(option) => option.organization}
                                        value={selectedSearchOrg}
                                        onChange={handleSearchOrganization}
                                        placeholder="Search for an organization"
                                        multiple={true}
                                    />
                                    {
                                        showValidation && !(selectedSearchOrg?.length > 0) ? 
                                            <Typography color="error">{t("manage_org_admin.select_organization")}</Typography>
                                            : null
                                    }
                                </Stack>
                                <Box className="wpx_modal_btnCenter">
                                    <Button
                                        className="wpx_save__btn"
                                        variant="contained"
                                        startIcon={<SaveAsIcon />}
                                        label={t("common.save")}
                                        onClick={handleAddOrgAdmin}
                                    />
                                </Box>
                            </Box>
                        </DialogContentText>
                    </DialogContent>
                </DialogContent>
            </DialogModal>
        </>
    );
};

export default ManageOrgAdminList;
