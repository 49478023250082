import React, { useEffect, useState } from "react";
import { CONSTANTS } from "../../../constants/constants";
import useAxios from "../../../hooks/useAxios";
import Table from "../../../components/common/table/Table";
import Pagination from "../../../components/common/table/Pagination";
import DialogModal from "../../../components/common/modal/DialogModal";
import {
    Box,
    Container,
    DialogActions,
    DialogContent,
    DialogContentText,
    Stack,
    Typography,
} from "@mui/material";
import Button from "../../../components/common/button/Button";
import { useTranslation } from "react-i18next";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import InputField from "../../../components/common/input/InputField";
import SaveAsIcon from "@mui/icons-material/SaveAs";
import { mockAPIPath } from "../config/apiPath";
import AddIcon from "@mui/icons-material/Add";
import Select from "../../../components/common/input/Select";
import Skeleton from "../../../components/common/skeleton";
import { commonActions } from "../../../store/features/commonSlice";
import { useAppDispatch } from "../../../store/hooks";

interface editData{
    organization?: string,
    location?: string,
    country?: string,
}

const ManageOrganizationList = () => {
    const [t] = useTranslation();

    const dispatch = useAppDispatch();
    const [totalCount, setTotalCount] = useState<number>(0);
    const [limit, setLimit] = useState<number>(CONSTANTS.PAGINATION_LIMIT);
    const [currentPage, setCurrentPage] = useState<number>(CONSTANTS.FIRST_PAGE);
    const [orgList, setOrgList] = useState<any>([]);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false);
    const [isAddModalOpen, setIsAddModalOpen] = useState<boolean>(false);
    const [skeleton, setSkeleton] = useState<any>(true);
    const [editData, setEditData] = useState<editData>({organization:"", location:"" ,country: ""});
    const [showValidation, setShowValidation] = useState<boolean>(false);

    const {data} = useAxios({
        url: `${mockAPIPath.ORG_LIST}?page=${currentPage}&limit=${limit}`,
        method: "GET",
    });

    useEffect(() => {
        if (data) {
            setOrgList(data.data);
            if (data.totalCount && data.totalPages) {
                setTotalCount(data.totalCount);
            }
            setSkeleton(false);
        }
    }, [data]);

    const tableData=
    orgList?.map((item:any) => ({
        id: item.id,
        organization: item.organization,
        location: `${item.location}, ${item.country}`,
    })) ?? [];

    const columns = [
        { id: "id", label: t("common.s_no"), width: "10%" },
        { id: "organization", label: t("organization.org_name"), width: "40%"},
        { id: "location", label: t("organization.location"), width: "30%" },
        { id: "actions", label: t("common.action"), width: "20%" },
    ];

    const handleEdit = (user: any) => {
        const tempData ={organization: user.organization, location: user?.location?.split(",")[0], country: user?.location?.split(",")[1]};
        setEditData(tempData);
        setIsEditModalOpen(true);
        setShowValidation(false);
    };

    const handleDelete = (user: any) => {
        setIsDeleteModalOpen(true);
        console.log("Delete user:", user);
    };

    const handleModalDeleteButton = () => {
        setIsDeleteModalOpen(false);
        dispatch(commonActions.IS_SNACKBAR(true));
        dispatch(commonActions.SNACKBAR_MESSAGE("Organization is deleted successfully"));
    };

    const handleCloseEditModal = () => {
        setIsEditModalOpen(false);
    };

    const handleModalConfirmAction = () => {
        setIsDeleteModalOpen(false);
    };

    const handlePageChange = (newPage: number, newLimit: number) => {
        setCurrentPage(newPage);
        setLimit(newLimit);
    };

    const closeAddDataModal = () => {
        setIsAddModalOpen(false);
    };

    const countryOptions = [
        { value: "India", label: "India" },
        { value: "Japan", label: "Japan" },
        { value: "USA", label: "USA" },
    ];

    const handleFieldChange = (e:any, fieldName: string) => {
        const tempData: any ={...editData};
        tempData[fieldName] = e.target.value;
        setEditData(tempData);
    };

    const handleAddBtnClick = () => {
        setShowValidation(false);
        setIsAddModalOpen(true);
        setEditData({organization:"", location:"" ,country: ""});
    };

    const handleAddOrganization = () => {
        console.log("addData", editData);
        setShowValidation(true);
        if(editData?.organization && editData?.location && editData?.country){
            dispatch(commonActions.IS_SNACKBAR(true));
            dispatch(commonActions.SNACKBAR_MESSAGE("Organization is added successfully"));
            setIsAddModalOpen(false);
        }
    };

    const handleEditOrganization = () => {
        setShowValidation(true);
        console.log("editData", editData);
        if(editData?.organization && editData?.location && editData?.country){
            dispatch(commonActions.IS_SNACKBAR(true));
            dispatch(commonActions.SNACKBAR_MESSAGE("Organization is edited successfully"));
            setIsEditModalOpen(false);  
        }    
    };

    return (
        <Container>
            {skeleton ? (
                <Skeleton
                    variant="rectangular"
                    width={"100%"}
                    height={30}
                    count={15}
                />
            ) : (
                <>
                    <Table
                        data={tableData}
                        columns={columns}
                        onEdit={handleEdit}
                        onDelete={handleDelete}
                    />
                    <Pagination
                        totalCount={totalCount}
                        currentPage={currentPage}
                        pageChange={handlePageChange}
                        rowsPerPage={limit}
                        rowsPerPageChange={setLimit}
                    /></>
        
            )}
            <Box className="wpx_modal_btnCenter">
                <Button
                    className="wpx_plus__btn"
                    variant="contained"
                    onClick={handleAddBtnClick}
                    startIcon={<AddIcon />}
                />
            </Box>
            <DialogModal open={isDeleteModalOpen}>
                <Box sx={{ py: 2 }}>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {t("common.delete_confirmation_msg")}
                        </DialogContentText>
                        <DialogContentText id="alert-dialog-description">
                            {t("common.msg_to_proceed")}
                        </DialogContentText>
                    </DialogContent>

                    <Box className="wpx_textCenter">
                        <Button
                            className="wpx_save__btn"
                            label={t("common.yes")}
                            variant="contained"
                            startIcon={<CheckRoundedIcon />}
                            onClick={handleModalDeleteButton}
                        />
                        <Button
                            className="wpx_save__btn"
                            label={t("common.no")}
                            variant="contained"
                            startIcon={<ClearOutlinedIcon />}
                            onClick={handleModalConfirmAction}
                        />
                    </Box>
                </Box>
            </DialogModal>
            <DialogModal open={isEditModalOpen}>
                <DialogActions
                    className="wpx_manage__form__action__btn"
                    sx={{ px: 2, mt: 2, ml:"10px", justifyContent: "space-between"  }}
                >
                    <Typography sx={{fontWeight: "500", fontSize:"18px"}}>{t("organization.edit_organization")}</Typography>
                    <ClearOutlinedIcon
                        sx={{ cursor: "pointer" }}
                        onClick={handleCloseEditModal}
                    />
                </DialogActions>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        <Box component="form" className="wpx_input_field_style">
                            <Stack
                                component="form"
                                spacing={2}
                                noValidate
                                autoComplete="off"
                                className=""
                                sx={{ width: "500px" }}
                            >
                                <InputField value={editData?.organization} onChange = {(e)=>{handleFieldChange(e, "organization");}} variant="outlined" label="Organization" />
                                {
                                    showValidation && !editData?.organization ? 
                                        <Typography color="error">{t("organization.enter_organization")}</Typography>
                                        : null
                                }
                                <InputField value={editData?.location} onChange = {(e)=>{handleFieldChange(e, "location");}} variant="outlined" label="Location" />
                                {
                                    showValidation && !editData?.location ? 
                                        <Typography color="error">{t("organization.enter_location")}</Typography>
                                        : null
                                }
                                <Select options={countryOptions} value={editData?.country} label={"Country"} onChange = {(e)=>{handleFieldChange(e, "country");}}/>
                                {
                                    showValidation && !editData?.country ? 
                                        <Typography color="error">{t("organization.select_country_validation")}</Typography>
                                        : null
                                }
                            </Stack>
                            <Box className="wpx_modal_btnCenter">
                                <Button
                                    className="wpx_save__btn"
                                    variant="contained"
                                    startIcon={<SaveAsIcon />}
                                    label={t("common.save")}
                                    onClick={handleEditOrganization}                               
                                />
                            </Box>
                        </Box>
                    </DialogContentText>
                </DialogContent>
            </DialogModal>
            <DialogModal open={isAddModalOpen}>
                <DialogActions
                    className="wpx_manage__form__action__btn"
                    sx={{ px: 2, mt: 2, ml:"31px", justifyContent: "space-between"  }}
                >
                    <Typography sx={{fontWeight: "500", fontSize:"18px"}}>{t("organization.add_organization")}</Typography>
                    <ClearOutlinedIcon
                        sx={{ cursor: "pointer" }}
                        onClick={closeAddDataModal}
                    />
                </DialogActions>
                <DialogContent>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description">
                            <Box component="form" className="wpx_input_field_style">
                                <Stack
                                    component="form"
                                    spacing={2}
                                    noValidate
                                    autoComplete="off"
                                    className=""
                                    sx={{ width: "500px" }}
                                >
                                    <InputField value={editData?.organization ? editData?.organization : ""} onChange = {(e)=>{handleFieldChange(e, "organization");}} variant="outlined" label="Organization" />
                                    {
                                        showValidation && !editData?.organization ? 
                                            <Typography color="error">{t("organization.enter_organization")}</Typography>
                                            : null
                                    }
                                    <InputField value={editData?.location ? editData?.location : ""} onChange = {(e)=>{handleFieldChange(e, "location");}} variant="outlined" label="Location" />
                                    {
                                        showValidation && !editData?.location ? 
                                            <Typography color="error">{t("organization.enter_location")}</Typography>
                                            : null
                                    }
                                    <Select options={countryOptions} value={editData?.country} label={"Country"} onChange = {(e)=>{handleFieldChange(e, "country");}}/>
                                    {
                                        showValidation && !editData?.country ? 
                                            <Typography color="error">{t("organization.select_country_validation")}</Typography>
                                            : null
                                    }
                                </Stack>
                                <Box className="wpx_modal_btnCenter">
                                    <Button
                                        className="wpx_save__btn"
                                        variant="contained"
                                        startIcon={<SaveAsIcon />}
                                        label={t("common.save")}
                                        onClick={handleAddOrganization}
                                    />
                                </Box>
                            </Box>
                        </DialogContentText>
                    </DialogContent>
                </DialogContent>
            </DialogModal>

        </Container>
    );
};

export default ManageOrganizationList;
