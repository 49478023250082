import React, { useState } from "react";
import { Box, SelectChangeEvent, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import Button from "../../../components/common/button/Button";
import Select from "../../../components/common/input/Select";

const ChooseSpeciality = () => {

    const {t} = useTranslation();
    const [speciality, setSpeciality] = useState<string>("");

    const specialityArr =[
        {value: "Anesthesiology", label: "Anesthesiology"},
        {value: "Cardiology", label: "Cardiology"},
        {value: "Dermatology", label: "Dermatology"},
        {value: "Emergency Medicine", label: "Emergency Medicine"},
        {value: "Forensic Pathology", label: "Forensic Pathology"},
    ];

    const handleChange = (event: SelectChangeEvent<string>) => {
        setSpeciality(event.target.value);
    };

    const handleNext = () => {
        console.log("Selected speciality:", speciality);
    };

    return (
        <Box className= "wpx_specialist_container">
            <Typography variant = "h5">
                {t("choose_speciality.connect_specialist")}
            </Typography> 
            <Typography>
                {t("choose_speciality.choose_speciality_msg")}
            </Typography>

            <Box mt={2}>
                <Select options={specialityArr} value={speciality} onChange={handleChange} placeholder="Select specialist"/>
            </Box>
            <Box mt={2}>
                <Button
                    className = 'wpx_save__btn'
                    label = {t("common.next")}
                    variant = "contained"
                    onClick = {handleNext} 
                />
            </Box>
        </Box>
    );
};

export default ChooseSpeciality;