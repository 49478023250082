export const mockAPIPath = {
    ACTIVE_SESSION_LIST: "/json-schemas/ActiveSessionList.json",
    USER_CHAT_HISTORY_LIST: "/json-schemas/ChatHistoryList.json",
    SPECIALITY_LIST: "/json-schemas/SpecialityList.json",
    REFER_SPECIALIST: "/json-schemas/ReferSpecialistList.json"
};

export const serverAPIPath = {
    ACTIVE_SESSION_LIST: "", 
};
