export const mockAPIPath = {
    ORG_LIST: "/json-schemas/OrganizationList.json",
    CSR_LIST: "/json-schemas/CSRList.json",
    ORG_ADMIN_LIST: "/json-schemas/OrgAdminList.json"   
};

export const serverAPIPath = {
    ORG_LIST: "", 
    ORG_ADMIN_LIST:""
};
