import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, InputAdornment, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import InputField from "../../../components/common/input/InputField";
import Button from "../../../components/common/button/Button";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../constants/routes";
import Select from "../../../components/common/input/Select";

const EditDetail = () => {

    const { t } = useTranslation();
    const navigate = useNavigate();

    const [weight, setWeight] = useState<number  | null>(null);
    const [height, setHeight] = useState<number  | null>(null);
    const [bloodGroup, setBloodGroup] = useState<string>("");

    const bloodGroupArr = [
        { value: "A+", label: "A+" },
        { value: "A-", label: "A-" },
        { value: "B+", label: "B+" },
        { value: "B-", label: "B-" },
        { value: "O+", label: "O+" },
        { value: "O-", label: "O-" },
        { value: "AB+", label: "AB+" },
        { value: "AB-", label: "AB-" }
    ];

    const handleWeight = (e: React.ChangeEvent<HTMLInputElement>) => {
        setWeight(Number(e.target.value));
    };

    const handleHeight = (e: React.ChangeEvent<HTMLInputElement>) => {
        setHeight(Number(e.target.value));
    };

    const handleBloodGroup = (e: any) => {
        setBloodGroup(e.target.value);
    };

    const handleNext = () => {
        navigate(ROUTES.CSR.PATEINT_CHAT_HISTORY);
    };

    const customWeightElement = () => {
        return (<InputAdornment position="end">KG</InputAdornment>);
    };

    const customHeightElement = () => {
        return (<InputAdornment position="end">CM</InputAdornment>);
    };
    


    return (
        <Box className="wpx_registration_container">
            <Typography variant="h5">{t("new_user.basic_details")}</Typography>
            <Typography>{t("new_user.private_informations")}</Typography>
            <Box component="form" mt={2}>
                <Grid container spacing={2} mt={2}>
                    <Grid size={{ xs: 10, sm: 5, md: 5, lg: 4 }}>

                        <InputField
                            type="number"
                            value={weight}
                            placeHolder={t("user_edit.weight")}
                            onChange={(e) => handleWeight(e)}
                            customIcon={true}
                            customIconElement={customWeightElement}
                        />
                    </Grid>
                    <Grid size={{ xs: 10, sm: 5, md: 5, lg: 4 }}>
                        <InputField
                            type="number"
                            value={height}
                            placeHolder={t("user_edit.height")}
                            onChange={(e) => handleHeight(e)}
                            customIcon={true}
                            customIconElement={customHeightElement}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2} mt={2}>
                    <Grid size={{ xs: 10, sm: 5, md: 5, lg: 4 }}>
                        <Select
                            value={bloodGroup}
                            options={bloodGroupArr}
                            placeholder={t("user_edit.blood_group")}
                            onChange={(e) =>handleBloodGroup(e)}
                            fullWidth={true}
                        /> 


                        {/* <InputField 
                            value={bloodGroup} 
                            placeHolder={t("user_edit.blood_group")}
                            onChange={(e) => handleBloodGroup(e)}
                        /> */}
                    </Grid>
                </Grid>
                <Grid container spacing={2} mt={2}> 
                    <Grid size={{ xs: 10, sm: 5, md: 5, lg: 4 }}>
                        <Button
                            className="wpx_save__btn"
                            label="Save"
                            variant="contained"
                            onClick={handleNext}
                        />                    
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
};

export default EditDetail;
