import React, { useEffect, useState } from "react";
import Table from "../../../components/common/table/Table";
import useAxios from "../../../hooks/useAxios";
import { mockAPIPath } from "../config/apiPath";
import { Box, IconButton, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { useTranslation } from "react-i18next";
import Button from "../../../components/common/button/Button";
import ModeIcon from "@mui/icons-material/Mode";
import ChatSession from "../../../components/chatSession";
import { useAppSelector } from "../../../store/hooks";
import { specialityListType } from "../../../types/CommonType";
import Skeleton from "../../../components/common/skeleton";
import { Link, useNavigate } from "react-router-dom";
import { ROUTES } from "../../../constants/routes";
import AddIcon from "@mui/icons-material/Add";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ChatIcon from "@mui/icons-material/Chat";



const ChatHistory = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [chatHistoryList, setChatHistoryList] = useState<any>([]);
    // const [speciality, setSpeciality] = useState<string>("");
    const [startConversation, setStartConversation] = useState<boolean>(false);
    const userRole = useAppSelector((state) => state.common.userRole);
    const [isChatScreenOpen, setIsChatScreenOpen] = useState<boolean>(false);
    const [skeleton, setSkeleton] = useState<any>(true);


    const [specialityList, setSpecialtyList] = useState<specialityListType>([]);

    const { data: specialityListApiData } = useAxios({
        url: `${mockAPIPath.SPECIALITY_LIST}`,
        method: "GET",
    });

    useEffect(() => {
        setSpecialtyList(specialityListApiData?.data);
    }, [specialityListApiData]);


    const handleForwardClick = (rowData: any) => {       
        if (rowData.chatCompleted){
            setStartConversation(true);
        } else {
            setIsChatScreenOpen(true);
        }
    };

    const handleEdit = () => {
        navigate(ROUTES.CSR.USER_REGISTRATION, {
            state: { 
                from: "edit",
                editData: {
                    aadhaar: "123456789012", 
                    name: "Rahul Misra",
                    mobile: "9876543210",
                    gender: "Male",
                    dob: "01/01/1984"
                }
            }
        });
    };

    const handleStartSession = () =>{
        setStartConversation(true);
    };

    const chatHistoryListApiCall = useAxios({
        url: `${mockAPIPath.USER_CHAT_HISTORY_LIST}`,
        method: "GET",
    });

    useEffect(() => {
        const { data } = chatHistoryListApiCall;
        if (data) {
            setChatHistoryList(data.data);
        }
        setSkeleton(false);
    }, [chatHistoryListApiCall]);

    // const tableData =
    //     chatHistoryList?.map((item: any) => ({
    //         date: item.date,
    //         organizationName: item.organizationName,
    //         reasonForVisit: item.reasonForVisit,
    //         doctorName: item.doctorName,
    //         chatCompleted: item.chatCompleted,
    //         subHistory: item.subHistory ?? []
    //     })) ?? [];

    const columns = [
        { id: "date", label: "Date", width: "10%" },
        { id: "organizationName", label: "Organization Name", width: "30%" },
        { id: "reasonForVisit", label: "Reason for visit", width: "30%" },
        { id: "doctorName", label: "Doctor's name", width: "20%" },
        { id: "actions", label: "Actions", width: "10%" },
    ];

    const handleCustomAction = (rowData: any) => {
        return (
            rowData?.chatCompleted ?  <IconButton  onClick ={() => handleForwardClick(rowData)} ><ArrowForwardIcon /> </IconButton> : <ChatIcon onClick ={() => handleForwardClick(rowData)}/>
        );
    };

    return (
        <>
            <Grid container mt={6} ml={2} mr={2}>
                <Grid size={{ xs: 12, sm: 12, md: 4, lg: 6 }}>
                    <Grid container spacing={5} mt={2}>
                        <Typography> Rahul Mishra </Typography>
                        <ModeIcon onClick={handleEdit}/>
                    </Grid>  
                    <Grid size={{ xs: 10, sm: 6, md: 5, lg: 6 }}>                       
                        <Typography> Male, 40 years old</Typography>
                    </Grid> 
                </Grid>
                <Grid size={{ xs: 12, sm: 12, md: 8, lg: 6 }}>
                    {/* <Box component="a"
                        className="wpx_chat_icon"
                        label={t("chat_history.start_over")}
                        onClick={handleStartSession}
                    ></Box> */}
                    <Link to={ROUTES.COMMON.USER_VERIFICATION} className="wpx_chat_icon">{t("chat_history.start_over")}</Link>
                    <Typography sx={{ float: "right", mr: "4px" }}>{t("chat_history.new_session")}</Typography>

                </Grid>
            </Grid>
            <Box className="wpx_modal_btnCenter">
                <Button
                    className="wpx_plus__btn"
                    variant="contained"
                    onClick={() => handleStartSession()}
                    startIcon={<AddIcon />}
                />
            </Box>
            {skeleton ? (
                <Skeleton
                    variant="rectangular"
                    width={"100%"}
                    height={30}
                    count={15}
                />
            ) : (
                <Table
                    data={chatHistoryList}
                    columns={columns}
                    // handleForward={handleForwardClick}
                    handleCustomAction={handleCustomAction}
                />
            )}
            <ChatSession
                startConversation={startConversation}
                setStartConversation={setStartConversation}
                specialityList={specialityList}
                userRole={userRole}
                isChatScreenOpen={isChatScreenOpen}
                setIsChatScreenOpen={setIsChatScreenOpen}
            />
        </>
    );
};

export default ChatHistory;