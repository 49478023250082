import { UserType } from "../enum/UserTypes";

export const CONSTANTS = {
    SUPER_ADMIN: UserType.USER_TYPE_SA,
    ORG_ADMIN: UserType.USER_TYPE_OA,
    MEDICAL_PROF: UserType.USER_TYPE_MP,
    CUSTOMER_SUPPORT: UserType.USER_TYPE_CSR,
    PAGINATION_LIMIT: 15,
    FIRST_PAGE:1
};

