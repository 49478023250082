import React from "react";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { assetPath } from "../../../../utils/assetPath";

const Home = () => {

    const {t} = useTranslation();

    return (
        <Box className = "wpx_login__subContainer">
            <Typography variant = "h5">
                {t("home.welcome")}
                <Box component="img" className="wpx_login__home__warpxLogoImg" src={assetPath("images/logos/dark-logo.svg")} alt="Warpx" />
            </Typography>
            <Typography variant = "subtitle1" color = "textSecondary">
                {t("home.begin_adventure")}
            </Typography>
        </Box> 
    );
};

export default Home;