import React, { useEffect, useState } from "react";
import { CONSTANTS } from "../../../constants/constants";
import useAxios from "../../../hooks/useAxios";
import { ActiveSessionInterface } from "../../../interfaces/ActiveSessionInterface";
import Table from "../../../components/common/table/Table";
import Pagination from "../../../components/common/table/Pagination";
// import { useTranslation } from "react-i18next";
import { mockAPIPath } from "../config/apiPath";
import Grid from "@mui/material/Grid2";
import Select from "../../../components/common/input/Select";
import { Container, IconButton, InputAdornment, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";


const ActiveSessionList = () => {
    //const [t] = useTranslation();
    const [totalCount, setTotalCount] = useState<number>(0);
    const [limit, setLimit] = useState<number>(CONSTANTS.PAGINATION_LIMIT);
    const [currentPage, setCurrentPage] = useState<number>(CONSTANTS.FIRST_PAGE);
    const [orgList, setOrgList] = useState<ActiveSessionInterface[]>([]);
    const [speciality, setSpeciality] = useState<string>("");

    const specialityArr =[
        {value: "Assigned to my speciality", label: "Assigned to my speciality"},
        {value: "Cardiology", label: "Cardiology"},
        {value: "Dermatology", label: "Dermatology"},
        {value: "Emergency Medicine", label: "Emergency Medicine"},
        {value: "Forensic Pathology", label: "Forensic Pathology"},
    ];

    const handleChange = (event:any) => {
        setSpeciality(event.target.value);
    };

    const activeSessionListApiCall = useAxios({
        url: `${mockAPIPath.ACTIVE_SESSION_LIST}?page=${currentPage}&limit=${limit}`,
        method: "GET",
    });

    useEffect(() => {
        const { data } = activeSessionListApiCall;
        if (data) {
            setOrgList(data.data);
            if (data.totalCount && data.totalPages) {
                setTotalCount(data.totalCount);
            }
        }
    }, [activeSessionListApiCall]);

    const tableData =
    orgList?.map((item) => ({
        id: item.id,
        patientName: item.patientName,
        reasonForVisit: item.reasonForVisit,
        dateTime: item.dateTime,
    })) ?? [];

    const columns = [
        { id: "id", label: "S.No" },
        { id: "patientName", label: "Patient Name "},
        { id: "reasonforvisit", label: "Reason for Visit" },
        { id: "datetime", label: "Date & Time" },
    ];

    const handlePageChange = (newPage: number, newLimit: number) => {
        setCurrentPage(newPage);
        setLimit(newLimit);
    };


    return (
        <>
            <Container>
                <Grid container spacing={2} sx={{my:2}}>
                    <Grid size={{xs:12, sm:6, md:4, lg:3}}>
                        <TextField
                        // id="input-with-icon-textfield"
                            label="Search"                        
                            //onChange={handleSearchChange}
                            //value={search}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton >
                                            <SearchIcon />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>
                    <Grid size={{xs:12, sm:6, md:3, lg:4}}></Grid>
                    <Grid size={{xs:12, sm:6, md:3, lg:2}}>
                        <Select options={specialityArr} value={speciality} onChange={handleChange} placeholder="Week" fullWidth/>
                    </Grid>

                    <Grid size={{xs:12, sm:6, md:3, lg:3}}>
                        <Select options={specialityArr} value={speciality} onChange={handleChange} placeholder="Assigned to my speciality" fullWidth/>
                    </Grid>
                </Grid>
          
            
                <Table
                    data={tableData}
                    columns={columns}
                />
            </Container>
            <Pagination
                totalCount={totalCount}
                currentPage={currentPage}
                pageChange={handlePageChange}
                rowsPerPage={limit}
                rowsPerPageChange={setLimit}
            />
        </>
    );
};

export default ActiveSessionList;
