import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography, SelectChangeEvent } from "@mui/material";
import Grid from "@mui/material/Grid2";
import InputField from "../../../components/common/input/InputField";
import Select from "../../../components/common/input/Select";
import Button from "../../../components/common/button/Button";
import { useLocation, useNavigate } from "react-router-dom";
import { ROUTES } from "../../../constants/routes";

const UserRegistration = () => {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from;
    const editData = location.state?.editData || {};

    const [aadhaar, setAadhaar] = useState<string>(editData?.aadhaar || "");
    const [name, setName] = useState<string>(editData?.name || "");
    const [mobile, setMobile] = useState<string>(editData?.mobile || "");
    const [gender, setGender] = useState<string>(editData?.gender || "");
    const [dob, setDob] = useState<string>(editData?.dob || "");
    const [showAadhaarValidation, setShowAadhaarValidation] = useState<boolean>(false);
    const [showNameValidation, setShowNameValidation] = useState<boolean>(false);
    const [showMobileValidation, setShowMobileValidation] = useState<boolean>(false);
    const [showDobValidation, setShowDobValidation] = useState<boolean>(false);
    const [showGenderValidation, setShowGenderValidation] = useState<boolean>(false);

    const genderArr = [
        { value: "Male", label: "Male" },
        { value: "Female", label: "Female" },
        { value: "Others", label: "Others" },
    ];

    const handleChange = (event: SelectChangeEvent<string>) => {
        setGender(event.target.value);
        if (event.target.value){
            setShowGenderValidation(false);
        }
    };

    const handleDob = (event: SelectChangeEvent<string>) => {
        setDob(event.target.value);
        if (event.target.value){
            setShowDobValidation(false);
        }
    };

    const handleNext = () => {
        if (!aadhaar || aadhaar.length < 12) setShowAadhaarValidation(true);
        if (!name) setShowNameValidation(true);
        if (!mobile || mobile.length < 10) setShowMobileValidation(true);
        if (!dob) setShowDobValidation(true);
        if (!gender) setShowGenderValidation(true);
   
        if (aadhaar && aadhaar.length === 12 && name && mobile && mobile.length === 10 && dob && gender) {
            if (from === "edit") {
                navigate(ROUTES.CSR.USER_EDIT_DETAIL);
            }else{
                navigate(ROUTES.CSR.PATEINT_CHAT_HISTORY);
            }
        }
    };

    const handleInput = (inputVal: React.ChangeEvent<HTMLInputElement>) => {
        if (inputVal.target.name === "aadhaarNumber") {
            const pattern = /^\d{0,12}$/;
            if (pattern.test(inputVal.target.value)) {
                setAadhaar(inputVal.target.value); 
                setShowAadhaarValidation(true);
             
                if(inputVal?.target?.value?.length > 11){
                    setShowAadhaarValidation(false);
                }
            }
        } else if (inputVal.target.name === "fullName") {
            setName(inputVal.target.value);
            // setShowAadhaarValidation(false);
        } else if (inputVal.target.name === "mobile") {
            const pattern = /^\d{0,10}$/;
            if (pattern.test(inputVal.target.value)) {
                setMobile(inputVal.target.value);
                setShowMobileValidation(true);

                if(inputVal?.target?.value?.length > 9){
                    setShowMobileValidation(false);
                }
            }
        }
    };

    const isFormComplete = aadhaar.length === 12 && name && mobile.length === 10 && gender && dob;

    return (
        <Box className="wpx_registration_container">
            <Typography variant="h5">{t("new_user.basic_details")}</Typography>
            <Typography>{t("new_user.private_informations")}</Typography>
            <Box component="form" mt={2}>
                <Grid container spacing={2} mt={2}>
                    <Grid size={{ xs: 10, sm: 5, md: 5, lg: 4 }}>
                        <InputField
                            value={aadhaar}
                            placeHolder={t("new_user.aadhaar")}
                            name="aadhaarNumber"
                            onChange={(e) => handleInput(e)}
                        />
                        {
                            showAadhaarValidation? 
                                <Typography color="error" sx={{ ml: 2, mt:1 }}>
                                    {"Please fill 12 digit aadhaar no."}
                                </Typography>: null
                        }
                    </Grid>
                    <Grid size={{ xs: 10, sm: 5, md: 5, lg: 4 }}>
                        <InputField
                            value={name}
                            placeHolder={t("new_user.name")}
                            name="fullName"
                            onChange={(e) => handleInput(e)}
                        />
                        {
                            showNameValidation && !name? 
                                <Typography color="error" sx={{ ml: 2, mt:1 }}>
                                    {"Please fill name"}
                                </Typography>: null
                        }
                    </Grid>
                </Grid>
                <Grid container spacing={2} mt={2}>
                    <Grid size={{ xs: 10, sm: 5, md: 5, lg: 4 }}>
                        <InputField 
                            value={mobile} 
                            placeHolder={t("new_user.mobile")}
                            name="mobile" 
                            onChange={(e) => handleInput(e)}
                        />
                        {
                            showMobileValidation? 
                                <Typography color="error" sx={{ ml: 2, mt:1 }}>
                                    {"Please fill 10 digit mobile no."}
                                </Typography>: null
                        }
                    </Grid>
                    <Grid size={{ xs: 10, sm: 5, md: 5, lg: 4 }}>
                        <InputField
                            type="date"
                            value={dob}
                            name="dob"
                            onChange={(e) => handleDob(e)}
                        />
                        {
                            showDobValidation? 
                                <Typography color="error" sx={{ ml: 2, mt:1 }}>
                                    {"Please select DOB"}
                                </Typography>: null
                        }
                    </Grid>
                </Grid>
                <Grid container spacing={2} mt={2}>
                    <Grid size={{ xs: 10, sm: 5, md: 5, lg: 4 }}>
                        <Select
                            value={gender}
                            options={genderArr}
                            onChange={handleChange}
                            placeholder={t("new_user.gender")}
                            formClassName="wpx_gender_width"
                        />   
                        {
                            showGenderValidation? 
                                <Typography color="error" sx={{ ml: 2, mt:1 }}>
                                    {"Please select gender"}
                                </Typography>: null
                        }                
                    </Grid>
                </Grid>
                <Grid container spacing={2} mt={2}> 
                    <Grid size={{ xs: 10, sm: 5, md: 5, lg: 4 }}>
                        <Button
                            className={`wpx_verification__wrapper__disabledBtn ${isFormComplete ? "wpx_verification__wrapper__disabledBtn__bgChange" : ""}`}
                            label={t("common.next")}
                            variant="contained"
                            onClick={handleNext}
                            // disabled={!isFormComplete}
                        />                    
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
};

export default UserRegistration;
