import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./styles/main.scss";
import { ROUTES } from "./constants/routes";
import ManageOrgAdminList from "./modules/SA/pages/OrgAdmin";
import ManageOrganizationList from "./modules/SA/pages/Organization";
import Login from "./modules/auth/login";
import OrganizationSelection from "./modules/organizationSelection";
import ManageMedProfList from "./modules/OA/pages/MedProf";
import Header from "./components/common/Header";
import ChooseSpeciality from "./modules/CSR/pages/SelectSpeciality";
import UserRegistration from "./modules/CSR/pages/UserRegistration";
import ActiveSessionList from "./modules/MP/pages/ActiveSession";
import UserChatHistoryList from "./modules/MP/pages/UserChatHistory";
import ChatHistory from "./modules/CSR/pages/ChatHistory";
import ReferSpecialist from "./modules/MP/pages/ReferSpecialist";
import IncorrectRoute from "./components/common/error/404";
import ManageCSRList from "./modules/OA/pages/CSR";
import SAManageCSRList from "./modules/SA/pages/CSR";
import UserEditDetails from "./modules/CSR/pages/UserEditDetails";
import UserVerification from "./modules/common/UserVerification";

function App() {

    return (
        <BrowserRouter>
            <Header />
            <Routes>
                <Route path={ROUTES.INITIAL_PAGE} element={<Login />} />
                <Route path={ROUTES.COMMON.USER_VERIFICATION} element={<UserVerification />}/>

                <Route path={ROUTES.ORGANIZATION_SELECTION} element={<OrganizationSelection />} />
                <Route path={ROUTES.SA.MANAGE_ORG} element={<ManageOrganizationList />} />
                <Route path={ROUTES.SA.MANAGE_OA} element={<ManageOrgAdminList />} />
                <Route path={ROUTES.OA.MANAGE_MP} element={<ManageMedProfList />} />
                {/* <Route path={ROUTES.CSR.USER_VERIFICATION} element={<UserVerification />} /> */}
                <Route path={ROUTES.CSR.SELECT_SPECIALITY} element={<ChooseSpeciality />} />
                <Route path={ROUTES.CSR.USER_REGISTRATION} element={<UserRegistration />} />
                <Route path={ROUTES.CSR.PATEINT_CHAT_HISTORY} element={<ChatHistory />} />
                <Route path={ROUTES.CSR.USER_EDIT_DETAIL} element={<UserEditDetails />} />
                <Route path={ROUTES.SA.MANAGE_CSR} element={<SAManageCSRList/>} />
                <Route path={ROUTES.MP.REFER_SPECIALIST} element={<ReferSpecialist />} />
                <Route path={ROUTES.MP.ACTIVE_SESSION_LIST} element={<ActiveSessionList />} />
                {/* <Route path={ROUTES.MP.PATIENT_VERIFICATION} element={<PatientVerification />} /> */}
                <Route path={ROUTES.MP.USER_CHAT_HISTORY} element={<UserChatHistoryList />} />
                <Route path={ROUTES.OA.MANAGE_CSR} element={<ManageCSRList/>} />

                <Route path="*" element={<IncorrectRoute />} />
            </Routes>
        </BrowserRouter>
    );
}

export default App;
