import React, { useEffect, useState } from "react";
import { CONSTANTS } from "../../../constants/constants";
import useAxios from "../../../hooks/useAxios";
import Table from "../../../components/common/table/Table";
import Pagination from "../../../components/common/table/Pagination";
import Grid from "@mui/material/Grid2";
import Select from "../../../components/common/input/Select";
import { Container } from "@mui/material";
import { mockAPIPath } from "../config/apiPath";
import InputField from "../../../components/common/input/InputField";
import { ROUTES } from "../../../constants/routes";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Skeleton from "../../../components/common/skeleton";

const ActiveSessionList = () => {

    const [t] = useTranslation();

    const [totalCount, setTotalCount] = useState<number>(0);
    const [limit, setLimit] = useState<number>(CONSTANTS.PAGINATION_LIMIT);
    const [currentPage, setCurrentPage] = useState<number>(CONSTANTS.FIRST_PAGE);
    const [activeSessionList, setActiveSessionList] = useState<any>([]);
    const [assignedFilter, setAssignedFilter] = useState<string>("All");
    const [timeFilter, setTimeFilter] = useState<string>("Today");
    const [search, setSearch] = useState<string>("");
    const [skeleton, setSkeleton] = useState<any>(true);

    const activeSessionListApiCall = useAxios({
        url: `${mockAPIPath.ACTIVE_SESSION_LIST}?page=${currentPage}&limit=${limit}`,
        method: "GET",
    });

    useEffect(() => {
        const { data } = activeSessionListApiCall;
        if (data) {
            setActiveSessionList(data.data);
            if (data.totalCount && data.totalPages) {
                setTotalCount(data.totalCount);
            }
            setSkeleton(false);
        }
    }, [activeSessionListApiCall]);

    const assignedFilterOptions =[
        {value: "All", label: "All"},
        {value: "Assigned to my speciality", label: "Assigned to my speciality"},
        {value: "Assigned to me", label: "Assigned to me"},
    ];

    const timeFilterOptions =[
        {value: "Today", label: "Today"},
        {value: "Week", label: "Week"},
    ];

    const handleAssignFilter = (event:any) => {
        setAssignedFilter(event.target.value);
    };

    const handleTimeFilter = (event:any) => {
        setTimeFilter(event.target.value);
    };

    const handleProductNameClick = (item: any) => {
        return (
            <Link to={`${ROUTES.MP.USER_CHAT_HISTORY}`} className="wpx_link_style">
                {item}
            </Link>             
        );
    };

    const tableData =
    activeSessionList?.map((item:any) => ({
        id: item.id,
        patientName: handleProductNameClick(item.patientName),
        reasonForVisit: item.reasonForVisit,
        dateTime: item.dateTime,
    })) ?? [];

    const columns = [
        { id: "id", label: t("common.s_no"), width: "10%"},
        { id: "patientName", label: t("active_session_list.patient_name"), width: "20%"},
        { id: "reasonForVisit", label:t("active_session_list.reason_for_visit"), width: "50%" },
        { id: "dateTime", label: t("active_session_list.date_time"), width: "20%" },
    ];

    const handlePageChange = (newPage: number, newLimit: number) => {
        setCurrentPage(newPage);
        setLimit(newLimit);
    };

    return (
        <>
            <Container>
                <Grid container spacing={2} sx={{my:2}}>
                    <Grid size={{xs:12, sm:12, md:5, lg:3}}>
                        <InputField value= {search} placeHolder="Search by name or aadhar" variant="outlined" onChange = {(e)=> setSearch(e.target.value)} customIcon />
                    </Grid>
                    <Grid size={{xs:0, sm:0, md:0, lg:4}}></Grid>
                    <Grid size={{xs:12, sm:12, md:3, lg:2}}>
                        <Select options={timeFilterOptions} value={timeFilter} onChange={handleTimeFilter}  fullWidth/>
                    </Grid>

                    <Grid size={{xs:12, sm:12, md:3, lg:3}}>
                        <Select options={assignedFilterOptions} value={assignedFilter} onChange={handleAssignFilter}  fullWidth/>
                    </Grid>
                </Grid>
                {skeleton ? (
                    <Skeleton
                        variant="rectangular"
                        width={"100%"}
                        height={30}
                        count={15}
                    />
                ) : (        
                    <>
                        <Table
                            data={tableData}
                            columns={columns} />
                        
                    </>
                )}
            </Container>
            <Pagination
                totalCount={totalCount}
                currentPage={currentPage}
                pageChange={handlePageChange}
                rowsPerPage={limit}
                rowsPerPageChange={setLimit}
            />
        </>
    );
};

export default ActiveSessionList;
