import React, { useState } from "react";
import {
    Table as MUITable,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    IconButton,
    Checkbox
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

interface TableProps<T> {
  data: T[];
  columns: {
    id: string;
    label: string;
    width?: string;
  }[];
  requireCheckbox?: boolean
  onEdit?: (row: T) => void;
  onDelete?: (row: T) => void;
  onNavigate?: (row: T) => void;
  handleForward?: (row: T) => void;
  setSelectedChatHistory?: any;
  tableName?: string;
  handleCustomAction?: (rowData: T) => React.ReactElement;
}

const Table = <T extends Record<string, unknown>>({
    data,
    columns,
    requireCheckbox = false,
    onEdit,
    onDelete,
    onNavigate,
    setSelectedChatHistory,
    handleCustomAction
}: TableProps<T>) => {
    const [expandedRows, setExpandedRows] = useState<number[]>([]);
    const [selectedCheckBox, setSelectedCheckbox] =useState<any>(null);

    const handleExpandClick = (index: number) => {
        setExpandedRows((prev) =>
            prev.includes(index)
                ? prev.filter((i) => i !== index)
                : [...prev, index]
        );
    };

    const handleCheckboxClick = (e:any, index: number, row:any) => {
        if(index === selectedCheckBox){
            setSelectedCheckbox(null);
            setSelectedChatHistory(null);
        }else{
            setSelectedCheckbox(index);
            setSelectedChatHistory(row);
        }      
    };

    return (
        <TableContainer component={Paper} sx={{mt:4}}>
            <MUITable>
                <TableHead>
                    <TableRow>
                        {requireCheckbox ? 
                            <TableCell>
                            </TableCell>
                            : null
                        }
                        {columns.map((column) => (
                            <TableCell 
                                key={column.id as string}
                                sx={{ width: column.width || "auto" }}
                            >
                                {column.label}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.map((row : any, index: number) => {
                        const subHistoryData : any  = row?.subHistory || null;
                        return(
                            <>
                                <TableRow key={index}>
                                    {requireCheckbox ?
                                        <TableCell>
                                            {!(subHistoryData?.length > 0) ? 
                                                <Checkbox
                                                    checked = {selectedCheckBox === row?.id }
                                                    className = "tableCheckbox"
                                                    onChange = {(e)=> handleCheckboxClick(e, row?.id, row)}/> 
                                                :
                                                null
                                            }
                                        </TableCell>
                                        : null 
                                    }
                                    {columns.map((column) => {
                                        return(
                                            <TableCell
                                                key={String(column.id)}
                                                sx={{ width: column.width || "auto" }}

                                            >
                                                {column.id === "actions" ? (
                                                    <>
                                                        {onEdit && (
                                                            <IconButton onClick={() => onEdit(row)}>
                                                                <EditIcon className="wpx_action__btn_icon"/>
                                                            </IconButton>
                                                        )}
                                                        {onDelete && (
                                                            <IconButton onClick={() => onDelete(row)}>
                                                                <DeleteIcon className="wpx_action__btn_icon"/>
                                                            </IconButton>
                                                        )}
                                                        {onNavigate && (
                                                            <IconButton onClick={() => onNavigate(row)}>
                                                                <VisibilityIcon className="wpx_action__btn_icon"/>
                                                            </IconButton>
                                                        )}
                                                        {subHistoryData && (
                                                            subHistoryData.length > 0 ?
                                                                <IconButton onClick={() => handleExpandClick(index)}>
                                                                    {expandedRows.includes(index) ? <RemoveIcon/>: <AddIcon />}
                                                                </IconButton> :  null                            
                                                        )}
                                                        { !subHistoryData && handleCustomAction ? handleCustomAction(row) : null}
                                                    </>
                                                ) : (
                            row[column.id] as React.ReactNode
                                                )}
                                            </TableCell>
                                        );
                                    })}
                                </TableRow>
                                { expandedRows.includes(index)  && subHistoryData.length > 1 && (
                                    subHistoryData?.map((subRow: any, index:number)=>{
                                        return(
                                            <TableRow key={index} sx ={{backgroundColor: "#DDDDDD"}}>
                                                {requireCheckbox ?
                                                    <TableCell sx ={{paddingLeft :"35px"}}>
                                                        <Checkbox
                                                            checked = {selectedCheckBox === subRow?.id }
                                                            className="tableCheckbox"              
                                                            onChange = {(e)=> handleCheckboxClick(e, subRow?.id, subRow)}/> 
                                                
                                                    </TableCell>
                                                    : null 
                                                }
                                                {columns.map((column) => {
                                                    return(
                                                        <TableCell
                                                            key={String(column.id)}
                                                            sx={{ width: column.width || "auto", paddingLeft: "30px" }}  // Set width if provided
              
                                                        >
                                                            {column.id === "actions" ? (
                                                                <>
                                                                    {/* {handleForward && (
                                                                        <IconButton  onClick ={() => handleForward(row)} >
                                                                            {tableName === "CSRChatHistory" ? !row.chatCompleted ? <ChatIcon
                                                                                onClick={() => handleForward(row)}
                                                                            />                                                                  
                                                                                : <ArrowForwardIcon />: <SummarizeIcon/> }                                                                        </IconButton>
                                                                    )}    */}
                                                                    {handleCustomAction ? handleCustomAction(subRow) : null}                                                                                                           
                                                                </>
                                                            ) : (
                                                                subRow[column.id] as React.ReactNode
                                                            )}
                                                        </TableCell>
                                                    );
                                                })}                                      
                                            </TableRow>
                                        );
                                    })                           
                                )}
                            </>                         
                        );
                    })}
                </TableBody>
            </MUITable>
        </TableContainer>
    );
};

export default Table;
