export const ROUTES = {
    INITIAL_PAGE: "/",
    ORGANIZATION_SELECTION: "/organization/select",
    COMMON: {
        USER_VERIFICATION: "/user/verification"
    },
    SA: {
        MANAGE_ORG: "/sa/manage/org",
        MANAGE_OA: "/sa/manage/oa",
        MANAGE_CSR: "/sa/manage/csr"
    },
    CSR: {
        SELECT_SPECIALITY: "/csr/select/speciality",
        ACTIVE_SESSION:"csr/active-session",
        USER_REGISTRATION: "/csr/user/registration",
        PATEINT_CHAT_HISTORY: "/csr/pateint/history",
        USER_EDIT_DETAIL: "/csr/user/edit/detail"
    },
    OA: {
        MANAGE_MP: "/oa/manage/mp",
        MANAGE_CSR: "/oa/manage/csr"
    },
    MP: {
        ACTIVE_SESSION_LIST:"/mp/active/session",
        USER_CHAT_HISTORY: "/mp/user/chat/history",
        REFER_SPECIALIST: "/mp/refer/specialist"
    }
};