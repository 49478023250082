import React, { ReactNode } from "react";
import { Dialog, Slide, Box } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";

type DataModalProps = {
    open: boolean;
    onClose?: () => void;
    children: ReactNode
    className?: string;
};

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children: React.ReactElement }, 
    ref: React.Ref<unknown>
) {
    return <Slide direction="down" ref={ref} {...props}>{props.children}</Slide>; 
});


const DialogModal = (props:DataModalProps) => {
    
    const {children, open, onClose, className} = props;

    return (
        <>
            <Box className="wpx_manage__form ">
                <Dialog
                    open={open}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={onClose}
                    aria-describedby="modal-description"
                    className={`${className} manage__form_dialog`}
                >
                    {children}
                </Dialog>
            </Box>
        </>
    );
};

export default DialogModal;
