import React, { useEffect, useState } from "react";
import {
    Box,
    DialogActions,
    DialogContent,
    DialogContentText,
    Paper,
    Stack,
    TableContainer,
    Typography,
} from "@mui/material";
import Table from "../../../components/common/table/Table";
import AddIcon from "@mui/icons-material/Add";
import { useTranslation } from "react-i18next";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import SaveAsIcon from "@mui/icons-material/SaveAs";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import useAxios from "../../../hooks/useAxios";
import { mockAPIPath } from "../config/apiPath";
import Button from "../../../components/common/button/Button";
import DialogModal from "../../../components/common/modal/DialogModal";
import InputField from "../../../components/common/input/InputField";
import AutoComplete from "../../../components/common/input/AutoComplete";
import Pagination from "../../../components/common/table/Pagination";
import { CONSTANTS } from "../../../constants/constants";

import Skeleton from "../../../components/common/skeleton";
import { commonActions } from "../../../store/features/commonSlice";
import { useDispatch } from "react-redux";
// type ItemData = {
//   id: number;
//   name: string;
//   specialty: string;
//   email: string;
//   organizations: string;
// };


const ManageMedProfList = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [addDataModalopen, setAddDataModalOpen] = useState(false);
    const [specialityList, setSpecialtyList] = useState<any>([]);
    const [limit, setLimit] = useState<number>(CONSTANTS.PAGINATION_LIMIT);
    const [totalCount, setTotalCount] = useState<number>(0);
    const [currentPage, setCurrentPage] = useState<number>(CONSTANTS.FIRST_PAGE);
    const [mpList, setMpList] = useState<any>([]);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false);
    const [skeleton, setSkeleton] = useState<any>(true);
    const [editData, setEditData] = useState<any>(null);
    const [showValidation, setShowValidation] = useState<boolean>(false);
    const [selectedSpeciality, setSelectedSpeciality] = useState<any>(null);

    const { data } = useAxios({
        url: `${mockAPIPath.MP_LIST}`,
        method: "GET",
    });

    const { data: specialityListApiData } = useAxios({
        url: `${mockAPIPath.SPECIALITY_LIST}`,
        method: "GET",
    });

    useEffect(() => {
        if (data) {
            setMpList(data.data);
            if (data.totalCount && data.totalPages) {
                setTotalCount(data.totalCount);
            }
            setSkeleton(false);
        }
    }, [data]);

    useEffect(() => {
        setSpecialtyList(specialityListApiData?.data);
    }, [specialityListApiData]);

    const handlePageChange = (newPage: number, newLimit: number) => {
        setCurrentPage(newPage);
        setLimit(newLimit);
    };

    const handleCloseEditModal = () => {
        setIsEditModalOpen(false);
    };

    const handleDeleteConfirmationModal = () => {
        dispatch(commonActions.IS_SNACKBAR(true));
        dispatch(commonActions.SNACKBAR_MESSAGE("Medical Professional  is deleted successfully"));
        setIsDeleteModalOpen(false);
    };
    const handleConfirmAction = () => {
        console.log("Confirmed!");
        setIsDeleteModalOpen(false);
    };

    const addDataCloseDataModal = () => {
        setAddDataModalOpen(false);
    };


    const handleEditSpecialty = (event: React.SyntheticEvent, newValue: any) => {
        setSelectedSpeciality(newValue);
    };

    const handleEdit = (user:any) => {
        console.log("user",user);
        setIsEditModalOpen(true);
        setShowValidation(false);
        setEditData(user);
        const tempData = specialityList.filter((item:any)=>item.specialty === user.specialty);
        console.log("tempData",tempData);
        setSelectedSpeciality(tempData);

    };
    console.log("specialityList",specialityList);
    const handleDelete = () => {
        setIsDeleteModalOpen(true);
    };


    const tableData =
        mpList?.map((item: any) => ({
            id: item.id,
            name: item.name,
            specialty: item.specialty,
            email: item.email
        })) ?? [];

    const columns = [
        { id: "id", label: t("common.s_no"), width: "10%" },
        { id: "name", label: t("manage_mp_list.doctor_name"), width: "25%" },
        { id: "specialty", label: t("common.specialty"), width: "25%" },
        { id: "email", label: t("common.email"), width: "25%" },
        { id: "actions", label: t("common.action"), width: "25%" },
    ];

    
    const handleFieldChange = (e:any, fieldName: string) => {
        const tempData ={...editData};
        tempData[fieldName] = e.target.value;
        setEditData(tempData);
    };

    const handleAddBtnClick = () => {
        setShowValidation(false);
        setAddDataModalOpen(true);
        setEditData(null);
        setSelectedSpeciality(null);
    };

    const handleAddOrgAdmin = () => {
        setShowValidation(true);
        console.log("addData", editData, selectedSpeciality);
        if(editData?.name && editData?.email && selectedSpeciality?.length > 0){
            dispatch(commonActions.IS_SNACKBAR(true));
            dispatch(commonActions.SNACKBAR_MESSAGE("Medical Professional is added successfully"));
            setAddDataModalOpen(false);
        }
    };

    const handleEditOrgAdmin = () => {
        setShowValidation(true);
        console.log("editData", editData, selectedSpeciality);
        if(editData?.name && editData?.email && selectedSpeciality?.length>0){
            dispatch(commonActions.IS_SNACKBAR(true));
            dispatch(commonActions.SNACKBAR_MESSAGE("Medical Professional is edited successfully"));
            setIsEditModalOpen(false);  
        }    
    };


    return (
        <>
            <TableContainer component={Paper}>
                {skeleton ? (
                    <Skeleton
                        variant="rectangular"
                        width={"100%"}
                        height={30}
                        count={15}
                    />
                ) : (
                    <>
                        <Table
                            data={tableData}
                            columns={columns}
                            onEdit={handleEdit}
                            onDelete={handleDelete}
                        />
                        <Pagination
                            totalCount={totalCount}
                            currentPage={currentPage}
                            pageChange={handlePageChange}
                            rowsPerPage={limit}
                            rowsPerPageChange={setLimit}
                        />
                    </>

                )}
            </TableContainer>
            <Box className="wpx_modal_btnCenter">
                <Button
                    className="wpx_plus__btn"
                    variant="contained"
                    onClick={handleAddBtnClick}
                    startIcon={<AddIcon />}
                />
            </Box>

            <DialogModal open={addDataModalopen}>
                <DialogActions
                    className="wpx_manage__form__action__btn"
                    sx={{ px: 4, mt: 2, justifyContent: "space-between" }}
                >
                    <Typography sx={{fontWeight: "500", fontSize:"18px"}}>
                        {t("manage_mp_list.add_medical_professional")}
                    </Typography>
                    <ClearOutlinedIcon
                        sx={{ cursor: "pointer" }}
                        onClick={addDataCloseDataModal}
                    />
                </DialogActions>
                <DialogContent>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description">
                            <Box component="form" className="wpx_input_field_style">
                                <Stack
                                    component="form"
                                    spacing={2}
                                    noValidate
                                    autoComplete="off"
                                    className=""
                                    sx={{ width: "500px" }}
                                >
                                    <InputField value={editData?.name ? editData?.name : ""} onChange = {(e)=>{handleFieldChange(e, "name");}}  variant="outlined" label="Name"  />
                                    {
                                        showValidation && !editData?.name ? 
                                            <Typography color="error">{t("manage_csr.enter_name")}</Typography>
                                            : null
                                    }
                                    <AutoComplete
                                        label="Select speciality"
                                        options={specialityList}
                                        getOptionLabel={(option) => option.specialty}
                                        value={selectedSpeciality}
                                        onChange={handleEditSpecialty}
                                        placeholder= "Search for speciality"
                                        multiple={true}
                                        
                                    />
                                    {
                                        showValidation && !(selectedSpeciality?.length > 0) ? 
                                            <Typography color="error">Please select speciality</Typography>
                                            : null
                                    }
                                    <InputField value={editData?.email ? editData?.email : ""} onChange = {(e)=>{handleFieldChange(e, "email");}} variant="outlined" label="Email"  />
                                    {
                                        showValidation && !editData?.email ? 
                                            <Typography color="error">{t("manage_csr.enter_email")}</Typography>
                                            : null
                                    }
                                </Stack>
                                <Box className="wpx_modal_btnCenter">
                                    <Button
                                        className="wpx_save__btn"
                                        variant="contained"
                                        startIcon={<SaveAsIcon />}
                                        label={t("common.save")}
                                        onClick={handleAddOrgAdmin}
                                    />
                                </Box>
                            </Box>
                        </DialogContentText>
                    </DialogContent>
                </DialogContent>
            </DialogModal>

            <DialogModal
                open={isDeleteModalOpen}
            >
                <Box sx={{ py: 2 }}>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {t("manage_mp_list.delete_confirmation_message")}
                        </DialogContentText>
                    </DialogContent>

                    <Box className="wpx_textCenter">
                        <Button
                            className="wpx_save__btn"
                            label={t("common.yes")}
                            variant="contained"
                            startIcon={<CheckRoundedIcon />}
                            onClick={handleDeleteConfirmationModal}
                        />
                        <Button
                            className="wpx_save__btn"
                            label={t("common.no")}
                            variant="contained"
                            startIcon={<ClearOutlinedIcon />}
                            onClick={handleConfirmAction}
                        />
                    </Box>
                </Box>
            </DialogModal>

            <DialogModal open={isEditModalOpen} >
                <DialogActions
                    className="wpx_manage__form__action__btn"
                    sx={{ px: 4, mt: 2, justifyContent: "space-between" }}
                >
                    <Typography sx={{fontWeight: "500", fontSize:"18px"}}>{t("common.edit_profile")}</Typography>
                    <ClearOutlinedIcon
                        sx={{ cursor: "pointer" }}
                        onClick={handleCloseEditModal}
                    />
                </DialogActions>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        <Box component="form" className="wpx_input_field_style">
                            <Stack
                                component="form"
                                spacing={2}
                                noValidate
                                autoComplete="off"
                                className=""
                                sx={{ width: "500px" }}
                            >
                                <InputField
                                    value={editData?.name}
                                    onChange = {(e)=>{handleFieldChange(e, "name");}}
                                    variant="outlined"
                                    label="Name"
                                    
                                />
                                {
                                    showValidation && !editData?.name ? 
                                        <Typography color="error">{t("manage_csr.enter_name")}</Typography>
                                        : null
                                }
                                <AutoComplete
                                    label="Select speciality"
                                    options={specialityList}
                                    getOptionLabel={(option) => option.specialty}
                                    value={selectedSpeciality}
                                    onChange={handleEditSpecialty}
                                    className="wpx_autocomplete_style"
                                    placeholder= "Search for speciality"
                                    multiple={true}
                                    
                                />
                                {
                                    showValidation && !(selectedSpeciality?.length > 0) ? 
                                        <Typography color="error">Please select speciality</Typography>
                                        : null
                                }
                                <InputField
                                    value={editData?.email} 
                                    onChange = {(e)=>{handleFieldChange(e, "email");}}
                                    variant="outlined"
                                    label="Email"                                    
                                />
                                {
                                    showValidation && !editData?.adminEmail ? 
                                        <Typography color="error">{t("manage_csr.enter_email")}</Typography>
                                        : null
                                }
                            </Stack>
                            <Box className="wpx_modal_btnCenter">
                                <Button
                                    className="wpx_save__btn"
                                    variant="contained"
                                    startIcon={<SaveAsIcon />}
                                    label={t("common.save")}
                                    onClick={handleEditOrgAdmin}                                                               
                                />
                            </Box>
                        </Box>
                    </DialogContentText>
                </DialogContent>
            </DialogModal>
        </>
    );
};

export default ManageMedProfList;
