import MUISelect from "@mui/material/Select";
import React from "react";
import { FormControl, MenuItem, SelectChangeEvent, Typography } from "@mui/material";

interface SelectOption {
  value: string | number;
  label: string;
}

interface GenericSelectProps<T> {
  label?: string;
  value: any;
  options: SelectOption[];
  onChange?: (event: SelectChangeEvent<T>, child: React.ReactNode) => void;
  fullWidth?: boolean;
  disabled?: boolean;
  placeholder?: string
  formClassName?:string
  className?: string
}

const Select = <T extends string | number>({
    label,
    value,
    options,
    onChange,
    placeholder,
    formClassName,
    className,
    fullWidth = false,
    disabled = false,
}: GenericSelectProps<T>) => {
    return (
        <>
            <Typography component="label">{label}</Typography>
            <FormControl fullWidth={fullWidth} disabled={disabled} className={`${formClassName} wpx_select_box`}>              
                <MUISelect<T>
                    value={value}
                    onChange={onChange}
                    displayEmpty
                    renderValue={selected => selected ? selected : placeholder}
                    className={`${className} wpx_select`}
                >
                    {options.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </MUISelect>
            </FormControl>
        </>    
    );
};

export default Select;
