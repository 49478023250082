interface CommonType {
    userRole?: number | null;
    isLogin?: boolean,
    isSnackbar?: boolean,
    snackbarMessage?: string,
    userDetails?: {
        name?: string,
        userRole?: number | null,
        profile?: string,
        speciality?: string
    }
}

export const commonInitialValue: CommonType = {
    userRole: null,
    isLogin: false,
    isSnackbar: false,
    snackbarMessage: "Snackbar Message!",
    userDetails: {
        name: "",
        userRole: null,
        profile: "",
        speciality: ""
    }
};

export default CommonType;
