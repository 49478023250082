import React, { useState } from "react";
import Grid from "@mui/material/Grid2";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import InputField from "../../../components/common/input/InputField";
import { ROUTES } from "../../../constants/routes";
import Button from "../../../components/common/button/Button";
import { useAppSelector } from "../../../store/hooks";
import { CONSTANTS } from "../../../constants/constants";


const UserVerification = () => {

    const { t } = useTranslation();
    const navigate = useNavigate();

    const userRole = useAppSelector((state) => state.common.userRole);

    const [showOtpComp, setShowOtpComp] = useState(false);  
    const [aadhaar, setAadhaar] = useState<string>("");
    const [name, setName] = useState<string>("");
    const [mobile, setMobile] = useState<string>("");
    const [otp, setOtp] = useState<string>("");
    const [showAadhaarValidation, setShowAadhaarValidation] = useState<boolean>(false);
    const [showNameValidation, setShowNameValidation] = useState<boolean>(false);
    const [showMobileValidation, setShowMobileValidation] = useState<boolean>(false);
    const [showOtpValidation, setShowOtpValidation] = useState<boolean>(false);
    
    const handleInput = (inputVal: React.ChangeEvent<HTMLInputElement>) => {
        if (inputVal.target.name === "aadhaarNumber") {
            const pattern = /^\d{0,12}$/;
            if (pattern.test(inputVal.target.value)) {
                setAadhaar(inputVal.target.value);
                setShowAadhaarValidation(true);
                setShowMobileValidation(false);
                setShowNameValidation(false); 
                if(inputVal?.target?.value?.length > 11){
                    setShowAadhaarValidation(false);
                }
            }
        } else if (inputVal.target.name === "fullName") {
            setName(inputVal.target.value);   
            setShowAadhaarValidation(false);       
        } else if (inputVal.target.name === "mobile") {
            const pattern = /^\d{0,10}$/;
            if (pattern.test(inputVal.target.value)) {
                setMobile(inputVal.target.value);
                setShowMobileValidation(true);
                setShowNameValidation(true); 
                setShowAadhaarValidation(false);
                if(inputVal.target.value.length > 9){
                    setShowMobileValidation(false);                  
                }
            }
        } else if (inputVal.target.name === "otp") {
            const pattern = /^\d{0,6}$/;
            if (pattern.test(inputVal.target.value)) {
                setOtp(inputVal.target.value);
                setShowOtpValidation(true);
                if(inputVal.target.value.length > 5){
                    setShowOtpValidation(false);
                }
            }
        }
    };

    const handleAadhaarNext = () => {
        if (aadhaar.length === 12 && userRole !== CONSTANTS.MEDICAL_PROF) {
            setShowOtpComp(true);
        }else if ( aadhaar.length === 12 && userRole === CONSTANTS.MEDICAL_PROF){
            navigate(ROUTES.MP.USER_CHAT_HISTORY);
        }
    };
 
    const handleNameMobileNext = () => {
        if(mobile.length === 10 && userRole !== CONSTANTS.MEDICAL_PROF){
            setShowOtpComp(true); 
        } 
        if ( mobile.length === 10 && userRole === CONSTANTS.MEDICAL_PROF){
            navigate(ROUTES.MP.USER_CHAT_HISTORY);
        }
    };

    const handleClick = () => {
        if ((otp.length === 6) && (aadhaar === "111111111111" || mobile === "1111111111")){
            navigate(ROUTES.CSR.USER_REGISTRATION, {
                state: { 
                    from: "registration",
                    editData: {
                        aadhaar: "111111111111"
                    }
                }
            });
        }else if (otp.length === 6){
            navigate(ROUTES.CSR.PATEINT_CHAT_HISTORY);
        }
    };

    return (
        <>
            <Box className="wpx_verification__wrapper">
                <Grid container spacing={2} sx={{ my: 2, ml: 8 }}>
                    <Grid size={{ xs: 12, sm: 12, md: 8, lg: 4 }}>
                        <Box>
                            <Box sx={{ my: 2 }}>
                                <Typography component="h1">{t("user_verification.start_user_verification")}</Typography>
                                <Typography>{t("user_verification.select_user_verification")}</Typography>
                            </Box>
                            <InputField
                                placeHolder="Aadhaar Number"
                                variant="outlined"
                                className="wpx_verification__wrapper__input"
                                name="aadhaarNumber"
                                value={aadhaar}
                                onChange={handleInput}
                                disabled={(name || mobile) ? true: false}
                            />
                            {
                                showAadhaarValidation ? 
                                    <Typography color="error" sx={{ ml: 2 }}>
                                        {"Please fill 12 digit aadhaar no."}
                                    </Typography>: null
                            }

                            <Button
                                className={`wpx_verification__wrapper__disabledBtn ${aadhaar?.length > 11 ? "wpx_verification__wrapper__disabledBtn__bgChange" : ""}`}
                                onClick={handleAadhaarNext}
                                variant="contained"
                                label={t("common.next")}
                            />
                        </Box>

                        <Typography className="wpx_verification__wrapper__middle_gap">Or</Typography>

                        <Box>

                            <InputField
                                placeHolder="Full Name"
                                variant="outlined"
                                className="wpx_verification__wrapper__input"
                                name="fullName"
                                value={name}
                                disabled={aadhaar ? true: false}
                                onChange={handleInput}
                            />
                            {
                                showNameValidation && !name ? 
                                    <Typography color="error" sx={{ ml: 2 }}>
                                        {"Please fill name"}
                                    </Typography>: null
                            }
                            <InputField
                                placeHolder="Mobile Number"
                                variant="outlined"
                                className="wpx_verification__wrapper__input"
                                name="mobile"
                                value={mobile}
                                disabled={aadhaar ? true: false}
                                onChange={handleInput}
                            />
                            {
                                showMobileValidation ? 
                                    <Typography color="error" sx={{ ml: 2 }}>
                                        {"Please fill 10 digit mobile no."}
                                    </Typography>: null
                            }
                            <Button
                                className={`wpx_verification__wrapper__disabledBtn ${mobile?.length > 9 && name ? "wpx_verification__wrapper__disabledBtn__bgChange" : ""}`}
                                // disabled={!name || !mobile}
                                onClick={handleNameMobileNext}
                                variant="contained"
                                label="Next"
                            />                          
                        </Box>

                        {
                            showOtpComp && (<Box>
                                <Box sx={{ my: 2 }}>
                                    <Typography component="h1">{t("user_verification.verify_communications")}</Typography>
                                    <Typography>{t("user_verification.high_security_msg")}</Typography>
                                </Box>

                                <InputField placeHolder="One Time Password" variant="outlined" className="wpx_verification__wrapper__input" name="otp" value={otp} onChange={(e) => handleInput(e)} />
                                {
                                    showOtpValidation ? 
                                        <Typography color="error" sx={{ ml: 2 }}>
                                            {"Please fill 6 digit otp"}
                                        </Typography>: null
                                }
                                <Button
                                    className={`wpx_verification__wrapper__disabledBtn ${otp?.length > 5 ? "wpx_verification__wrapper__disabledBtn__bgChange" : ""}`}
                                    disabled = {!otp}
                                    variant="contained"
                                    label={t("common.next")}
                                    onClick={handleClick}
                                />
                            </Box>)
                        }
                    </Grid>
                </Grid>
            </Box>
        </>
    );
};

export default UserVerification;