import React from "react";
import { Box, Typography } from "@mui/material";
import Button from "../../../../components/common/button/Button";
import AppleIcon from "@mui/icons-material/Apple";
import FacebookIcon from "@mui/icons-material/Facebook";
import GoogleIcon from "@mui/icons-material/Google";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../../constants/routes";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { commonActions } from "../../../../store/features/commonSlice";
import { CONSTANTS } from "../../../../constants/constants";

const SocialAccountSelection = () => {

    const userRole = useAppSelector((state) => state.common.userRole);
    const dispatch = useAppDispatch();

    const {t} = useTranslation();
    const navigate = useNavigate();

    const handleSocialAccountSelection = () => {
        if(userRole === CONSTANTS.SUPER_ADMIN ){
            dispatch(commonActions.USER_DETAILS({"userRole": userRole, "name":"Super admin", "profile":""}));
            dispatch(commonActions.IS_LOGIN(true));
            navigate(ROUTES.SA.MANAGE_ORG);
        }else if(userRole === CONSTANTS.ORG_ADMIN ){
            dispatch(commonActions.USER_DETAILS({"userRole": userRole, "name":"Org admin", "profile":""}));
            navigate(ROUTES.ORGANIZATION_SELECTION);
        }else if( userRole === CONSTANTS.MEDICAL_PROF){
            dispatch(commonActions.USER_DETAILS({"userRole": userRole, "name":"Med prof", "profile":"", "speciality":"Cardiology"}));
            navigate(ROUTES.ORGANIZATION_SELECTION);
        }
        else if(userRole === CONSTANTS.CUSTOMER_SUPPORT){
            dispatch(commonActions.USER_DETAILS({"userRole": userRole, "name":"CSR", "profile":""}));
            dispatch(commonActions.IS_LOGIN(true));
            navigate(ROUTES.COMMON.USER_VERIFICATION);
        }
    };


    return (
        <>
            <Box className = "wpx_login__subContainer">
                <Typography variant = "h5">
                    {t("social_account_selection.choose_account")}
                </Typography>
                <Typography variant = "subtitle1" color = "textSecondary">
                    {t("common.introduction")}
                </Typography>
            </Box>
            <Box>
                <Button className = "wpx_login__socialAccountSelection__googleBtn" label =  {t("social_account_selection.sign_in_with_google")} startIcon = {<GoogleIcon/>} onClick = {handleSocialAccountSelection}/>
                <Button className = "wpx_login__socialAccountSelection__fbBtn" label = {t("social_account_selection.sign_in_with_facebook")} startIcon = {<FacebookIcon/>} onClick = {handleSocialAccountSelection}/>
                <Button className = "wpx_login__socialAccountSelection__appleBtn" label =  {t("social_account_selection.sign_in_with_apple")} startIcon = {<AppleIcon/>} onClick = {handleSocialAccountSelection}/>
            </Box>
        </>
    );
};

export default SocialAccountSelection;