import React from "react";
import { useTranslation } from "react-i18next";

const IncorrectRoute = () => {

    const {t} = useTranslation();

    return (
        <div className="container">
            <h1 className="first-four">4</h1>
            <div className="cog-wheel1">
                <div className="cog1">
                    <div className="top"></div>
                    <div className="down"></div>
                    <div className="left-top"></div>
                    <div className="left-down"></div>
                    <div className="right-top"></div>
                    <div className="right-down"></div>
                    <div className="left"></div>
                    <div className="right"></div>
                </div>
            </div>
            <h1 className="second-four">4</h1>
            <p className="wrong-para">{t("common.page_not_found")}</p>
        </div>
    );
};

export default IncorrectRoute;
