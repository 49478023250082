import { Box, Typography } from "@mui/material";
import React, { useRef } from "react";
import Button from "../../../common/button/Button";
import Modal from "../../../common/modal/Modal";

/* Modal for live camera feed and capture */
const CameraComponent = (props: any) => {
    const { capturedImage, setCapturedImage, videoRef, messages, setMessages, cameraOpen, setCameraOpen } = props;

    const canvasRef: React.MutableRefObject<any> = useRef(null);

    // Function to stop the camera
    const stopCamera = () => {
        const stream = videoRef.current?.srcObject as MediaStream;
        if (stream) {
            const tracks = stream.getTracks();
            tracks.forEach((track: MediaStreamTrack) => track.stop());
        }
    };

    // Capture the image from video and stop camera
    const handleCapture = () => {
        const canvas = canvasRef.current;
        const video = videoRef.current;
        if (canvas && video) {
            const context = canvas.getContext("2d");
            context.drawImage(video, 0, 0, canvas.width, canvas.height);
            const dataURL = canvas.toDataURL("image/png");
            setCapturedImage(dataURL);
        }
        
        // Stop the camera after capturing
        stopCamera();
    };

    // Upload the captured image and stop camera
    const handleUpload = () => {
        if (capturedImage) {
            const newMessages: any = [...messages, { message: capturedImage, sender: "user", type: "image" }];
            setMessages(newMessages);
            setCameraOpen(false);
            setCapturedImage(null);

            // Stop the camera after uploading
            stopCamera();
        }
    };

    // Close the camera and stop it
    const handleCameraClose = () => {
        stopCamera();
        setCameraOpen(false);
        setCapturedImage(null);
    };

    return (
        <Modal
            open={cameraOpen}
            onClose={handleCameraClose}
            aria-labelledby="camera-modal-title"
            aria-describedby="camera-modal-description"
        >
            <Box sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: 400,
                bgcolor: "background.paper",
                boxShadow: 24,
                p: 4,
                display: "flex",
                flexDirection: "column",
                alignItems: "center"
            }}>
                <Typography id="camera-modal-title" variant="h6" component="h2" gutterBottom>
                    Live Camera Preview
                </Typography>
                {capturedImage ? (
                    <img src={capturedImage} alt="Captured" style={{ width: "100%", marginBottom: "10px" }} />
                ) : (
                    <video ref={videoRef} style={{ width: "100%", marginBottom: "10px" }} />
                )}
                <canvas ref={canvasRef} style={{ display: "none" }} width="400" height="300"></canvas>
                <Button variant="contained" onClick={handleCapture} label="Capture" className="wpx_camera_button" />
                <Button variant="contained" onClick={handleCameraClose} label="Close" className="wpx_camera_button" />

                {capturedImage && (
                    <Button variant="contained" onClick={handleUpload} label="Upload" />
                )}
            </Box>
        </Modal>
    );
};

export default CameraComponent;
