import React, { useEffect, useState } from "react";
import { mockAPIPath } from "../config/apiPath";
import useAxios from "../../../hooks/useAxios";
import { Box, Container, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import Button from "../../../components/common/button/Button";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../constants/routes";
import { commonActions } from "../../../store/features/commonSlice";
import { useAppDispatch } from "../../../store/hooks";


/**
 * 
 * @returns Specialist list to choose
 */
const ReferSpecialist = () => {

    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [referSpecialistList, setReferSpecialistList] = useState<any>([]);

    const referSpecialistListApiCall = useAxios({
        url: `${mockAPIPath.REFER_SPECIALIST}`,
        method: "GET",
    });

    useEffect(() => {
        const { data } = referSpecialistListApiCall;
        if (data) {
            setReferSpecialistList(data.data);
        }
    }, [referSpecialistListApiCall]);

    const handleClickForward = () => {
        dispatch(commonActions.IS_SNACKBAR(true));
        dispatch(commonActions.SNACKBAR_MESSAGE("Rahul Misra referred to Dr. Mansh Kumar Successfully"));
        navigate(ROUTES.MP.USER_CHAT_HISTORY);
    };

    function handleBackClick(): void {
        navigate(ROUTES.MP.USER_CHAT_HISTORY);
    }

    return (
        <Container>
            <Box sx ={{p: 5}}>
                <Box>
                    <Typography variant="h5">Choose a specialist</Typography>
                    <Typography>Select a Medical Profissional to refer the patient</Typography>
                </Box>
                <Box sx={{ minHeight: "68vh", overflow: "auto", marginBottom: "10px", marginTop: "10px" }}>
                    <Grid container spacing={2.5} mt={2} >
                        {referSpecialistList.map((item: any, index: any) => {
                            return (
                                <Grid size={{ xs: 12, sm: 6, md: 4, lg: 2, xl: 2 }} key={index}>
                                    <Box>
                                        <Box component="img" sx={{ width: "100%" }} className="" src={item.img} alt={item.doctorName} />
                                        <Typography>{item.doctorName}</Typography>
                                        <Typography>{item.doctorSpeciality}</Typography>
                                    </Box>
                                </Grid>
                            );
                        })}
                    </Grid>
                </Box>
                <Grid>
                    <Button
                        className="wpx_forward_btn"
                        variant="contained"
                        label="Forward"
                        onClick={handleClickForward}
                    />
                    <Button
                        className="outlineBtn_reffered_mp"
                        variant="contained"
                        label="Back to report list"
                        onClick={handleBackClick}
                    />
                </Grid>
            </Box>
        </Container>
    );
};

export default ReferSpecialist;