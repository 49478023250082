import React from "react";
import MUIButton from "@mui/material/Button";
import { ButtonType } from "../../../types/ButtonType";

const Button = (props: ButtonType) => {
    const { variant, onClick, className, size, startIcon, disabled, label } = props;

    return(
        <MUIButton
            sx={{ textTransform: "capitalize", backgroundColor: "#000000" }}
            variant = {variant}
            onClick = {onClick}
            className = {className}
            size = {size}
            startIcon = {startIcon}
            disabled = {disabled}
        >
            {label}
        </MUIButton>
    );
};

export default Button;