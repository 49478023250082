import React from "react";
import MUIToggleButton from "@mui/material/ToggleButton";
import MUIToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { ToggleButtonType } from "../../../types/ToggleButtonType";

const ToggleButton = <T extends string | number> (props: ToggleButtonType<T>) => {
    const { value, className, onChange, options } = props;

    return (
        <MUIToggleButtonGroup
            className={className}
            value={value}
            exclusive
            onChange={onChange}
        >
            {options?.map((option, index) => (
                <MUIToggleButton key={index} value={option.value}>
                    {option.label}
                </MUIToggleButton>
            ))}
        </MUIToggleButtonGroup>
    );
};

export default ToggleButton;
