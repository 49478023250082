import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import ToggleButton from "../../../components/common/button/ToggleButton";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../constants/routes";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { commonActions } from "../../../store/features/commonSlice";
import Button from "../../../components/common/button/Button";
import { CONSTANTS } from "../../../constants/constants";
import { assetPath } from "../../../utils/assetPath";

type ItemData = {
    id: number;
    name: string;
};

type OrganizationType = {
    label: string;
    value: string;
};

type PropsType = {
    organizationList: ItemData[];
};

const MultipleOrganization = (props: PropsType) => {

    const { organizationList } = props;
    const userRole = useAppSelector((state) => state.common.userRole);
    const navigate = useNavigate();
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const [organization, setOrganization] = useState<OrganizationType[]>([]);
    const [selectedOrg, setSelectedOrg] = useState<string>("");

    const handleOrganizationSelection = (
        e: React.MouseEvent<HTMLElement>,
        newValue: string | null
    ) => {
        if (newValue !== null) {
            setSelectedOrg(newValue);
        }
    };

    const handleOrgSelectionNext = () => {
        dispatch(commonActions.IS_LOGIN(true));
        if (userRole === CONSTANTS.ORG_ADMIN) {
            navigate(ROUTES.OA.MANAGE_MP);
        } else {
            navigate(ROUTES.MP.ACTIVE_SESSION_LIST);
        }
    };

    useEffect(() => {
        if (organizationList) {
            const updatedOrganization = organizationList.map((item) => ({
                label: item.name,
                value: item.name,
            }));

            setOrganization(updatedOrganization);
        }
    }, [organizationList]);

    return (
        <>
            <Box sx={{ position: "absolute", top: "10px", left: "20px", }} >
                <img src={assetPath("images/logos/dark-logo.svg")} alt="Logo" style={{ width: "200px" }} />
            </Box>
            <Box sx={{ maxWidth: "800px" , maxHeight: "200px"}}>
                <Box className="wpx_organizationSelection__subContainer">
                    <Typography variant="h5">
                        {t("multiple_organization.select_organiztion")}
                    </Typography>
                    <Typography variant="subtitle1" color="textSecondary">
                        {t("common.introduction")}
                    </Typography>
                </Box>
                <ToggleButton
                    options={organization}
                    value={selectedOrg}
                    onChange={handleOrganizationSelection}
                    className="wpx_organizationSelection__multiple_organization__toggleBtnGroup"
                />
                <Button
                    className="wpx_save__btn wpx_organizationSelection__multiple_organization__NextBtn"
                    variant="contained"
                    label="Next"
                    onClick={handleOrgSelectionNext}
                    disabled={selectedOrg ? false : true}
                />
            </Box>
        </>
    );
};

export default MultipleOrganization;
