import { configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import commonReducer from "./features/commonSlice";

const persistConfig = {
    key: "root",
    storage,
    blacklist: ["loaderss"],
};

const persistedCommonReducer = persistReducer(persistConfig, commonReducer);

const store = configureStore({
    reducer: {
        common: persistedCommonReducer,
    },
});

export type IRootState = ReturnType<typeof store.getState>
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const persistor = persistStore(store); 

export default store;