import { ROUTES } from "../constants/routes";

export const superAdminMenuConfig = [
    {   
        name: "Manage Organizations",
        path: ROUTES.SA.MANAGE_ORG
    },
    {   
        name: "Manage Organizations Admins",
        path: ROUTES.SA.MANAGE_OA
    },
    {   
        name: "Manage CSR",
        path: ROUTES.SA.MANAGE_CSR
    },
];

export const orgAdminMenuConfig = [
    {   
        name: "Manage MP",
        path: ROUTES.OA.MANAGE_MP
    },
    {   
        name: "Manage CSR",
        path: ROUTES.OA.MANAGE_CSR
    },
];

export const MPMenuConfig = [
    {   
        name: "Active Session",
        path: ROUTES.MP.ACTIVE_SESSION_LIST
    },
    {   
        name: "Walk in",
        path: ROUTES.COMMON.USER_VERIFICATION
    }
];