import React, { useEffect, useState } from "react";
import { CONSTANTS } from "../../../constants/constants";
import useAxios from "../../../hooks/useAxios";
import Table from "../../../components/common/table/Table";
import Pagination from "../../../components/common/table/Pagination";
import DialogModal from "../../../components/common/modal/DialogModal";
import {
    Box,
    DialogActions,
    DialogContent,
    DialogContentText,
    Stack,
    Typography,
} from "@mui/material";
import Button from "../../../components/common/button/Button";
import { useTranslation } from "react-i18next";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import InputField from "../../../components/common/input/InputField";
import SaveAsIcon from "@mui/icons-material/SaveAs";
import { mockAPIPath } from "../config/apiPath";
import AddIcon from "@mui/icons-material/Add";
import { useAppDispatch } from "../../../store/hooks";
import { commonActions } from "../../../store/features/commonSlice";

const ManageCSRList = () => {
    const [t] = useTranslation();

    const dispatch = useAppDispatch();

    const [totalCount, setTotalCount] = useState<number>(0);
    const [limit, setLimit] = useState<number>(CONSTANTS.PAGINATION_LIMIT);
    const [currentPage, setCurrentPage] = useState<number>(CONSTANTS.FIRST_PAGE);
    const [CSRList, setCSRList] = useState<any>([]);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false);
    const [isAddModalOpen, setIsAddModalOpen] = useState<boolean>(false);
    const [editData, setEditData] = useState<any>(null);
    const [showValidation, setShowValidation] = useState<boolean>(false);

    const CSRListApiCall = useAxios({
        url: `${mockAPIPath.CSR_LIST}?page=${currentPage}&limit=${limit}`,
        method: "GET",
    });

    useEffect(() => {
        const { data } = CSRListApiCall;
        if (data) {
            setCSRList(data.data);
            if (data.totalCount && data.totalPages) {
                setTotalCount(data.totalCount);
            }
        }
    }, [CSRListApiCall]);

    const tableData =
    CSRList?.map((item:any) => ({
        id: item.id,
        name: item.name,
        email: item.email,
    })) ?? [];

    const columns = [
        { id: "id", label: t("common.s_no"), width: "10%"  },
        { id: "name", label: t("manage_csr.csr_name"), width: "20%"  },
        { id: "email", label: t("manage_csr.email"), width: "20%"  },
        { id: "actions", label: t("common.action"), width: "10%" }
    ];

    const handleEdit = (user: any) => {
        setShowValidation(false);
        setIsEditModalOpen(true);
        setEditData(user);
    };

    const handleDelete = (user: any) => {
        setIsDeleteModalOpen(true);
        console.log("Delete user:", user);
    };

    const handleModalDeleteButton = () => {
        setIsDeleteModalOpen(false);
        dispatch(commonActions.IS_SNACKBAR(true));
        dispatch(commonActions.SNACKBAR_MESSAGE("CSR is deleted successfully"));
    };

    const handleCloseEditModal = () => {
        setIsEditModalOpen(false);
    };

    const handleModalConfirmAction = () => {
        setIsDeleteModalOpen(false);
    };

    const handlePageChange = (newPage: number, newLimit: number) => {
        setCurrentPage(newPage);
        setLimit(newLimit);
    };


    const closeAddDataModal = () => {
        setIsAddModalOpen(false);
    };

    const handleFieldChange = (e:any, fieldName: string) => {
        const tempData ={...editData};
        tempData[fieldName] = e.target.value;
        setEditData(tempData);
    };

    const handleAddBtnClick = () => {
        setShowValidation(false);
        setIsAddModalOpen(true);
        setEditData(null);
    };

    const handleAddCSR = () => {
        console.log("addData", editData);
        setShowValidation(true);
        if(editData?.name && editData?.email){
            dispatch(commonActions.IS_SNACKBAR(true));
            dispatch(commonActions.SNACKBAR_MESSAGE("CSR is added successfully"));
            setIsAddModalOpen(false);
        }
    };

    const handleEditCSR = () => {
        setShowValidation(true);
        console.log("editData", editData);
        if(editData?.name && editData?.email){
            dispatch(commonActions.IS_SNACKBAR(true));
            dispatch(commonActions.SNACKBAR_MESSAGE("CSR is edited successfully"));
            setIsEditModalOpen(false);  
        }    
    };

    return (
        <>
            <Table
                data={tableData}
                columns={columns}
                onEdit={handleEdit}
                onDelete={handleDelete}
            />
            <Pagination
                totalCount={totalCount}
                currentPage={currentPage}
                pageChange={handlePageChange}
                rowsPerPage={limit}
                rowsPerPageChange={setLimit}
            />

            <Box className="wpx_modal_btnCenter">
                <Button
                    className="wpx_plus__btn"
                    variant="contained"
                    onClick={handleAddBtnClick}
                    startIcon={<AddIcon />}
                />
            </Box>
            <DialogModal open={isDeleteModalOpen}>
                <Box sx={{ py: 2 }} >
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {t("common.delete_confirmation_msg")}
                        </DialogContentText>
                        <DialogContentText id="alert-dialog-description">
                            {t("common.msg_to_proceed")}
                        </DialogContentText>
                    </DialogContent>

                    <Box className="wpx_textCenter">
                        <Button
                            className="wpx_save__btn"
                            label={t("common.yes")}
                            variant="contained"
                            startIcon={<CheckRoundedIcon />}
                            onClick={handleModalDeleteButton}
                        />
                        <Button
                            className="wpx_save__btn"
                            label={t("common.no")}
                            variant="contained"
                            startIcon={<ClearOutlinedIcon />}
                            onClick={handleModalConfirmAction}
                        />
                    </Box>
                </Box>
            </DialogModal>
            <DialogModal open={isEditModalOpen}>
                <DialogActions
                    className="wpx_manage__form__action__btn"
                    sx={{ px: 2, mt: 2, ml:"10px", justifyContent: "space-between"  }}
                >
                    <Typography sx={{fontWeight: "500", fontSize:"18px"}}>{t("manage_csr.edit_csr")}</Typography>
                    <ClearOutlinedIcon
                        sx={{ cursor: "pointer" }}
                        onClick={handleCloseEditModal}
                    />
                </DialogActions>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        <Box component="form" className="wpx_input_field_style">
                            <Stack
                                component="form"
                                spacing={2}
                                noValidate
                                autoComplete="off"
                                className=""
                                sx={{ width: "500px" }}
                            >
                                <InputField value={editData?.name} variant="outlined" label="Name" onChange = {(e)=>{handleFieldChange(e, "name");}}/>
                                {
                                    showValidation && !editData?.name ? 
                                        <Typography color="error">{t("manage_csr.enter_name")}</Typography>
                                        : null
                                }
                                <InputField value={editData?.email} variant="outlined" label="Email"  onChange = {(e)=>{handleFieldChange(e, "email");}}/>
                                {
                                    showValidation && !editData?.email ? 
                                        <Typography color="error">{t("manage_csr.enter_email")}</Typography>
                                        : null
                                }
                            </Stack>
                            <Box className="wpx_modal_btnCenter">
                                <Button
                                    className="wpx_save__btn"
                                    variant="contained"
                                    startIcon={<SaveAsIcon />}
                                    label={t("common.save")}    
                                    onClick={handleEditCSR}                               
                                />
                            </Box>
                        </Box>
                    </DialogContentText>
                </DialogContent>
            </DialogModal>
            <DialogModal open={isAddModalOpen}>
                <DialogActions
                    className="wpx_manage__form__action__btn"
                    sx={{ px: 2, mt: 2, ml:"31px", justifyContent: "space-between"  }}
                >
                    <Typography sx={{fontWeight: "500", fontSize:"18px"}}>{t("manage_csr.add_csr")}</Typography>
                    <ClearOutlinedIcon
                        sx={{ cursor: "pointer" }}
                        onClick={closeAddDataModal}
                    />
                </DialogActions>
                <DialogContent>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description">
                            <Box component="form" className="wpx_input_field_style">
                                <Stack
                                    component="form"
                                    spacing={2}
                                    noValidate
                                    autoComplete="off"
                                    sx={{ width: "500px" }}
                                >
                                    <InputField value={editData?.name ? editData?.name : ""} onChange = {(e)=>{handleFieldChange(e, "name");}} variant="outlined" label="Name" />
                                    {
                                        showValidation && !editData?.name ? 
                                            <Typography color="error">{t("manage_csr.enter_name")}</Typography>
                                            : null
                                    }
                                    <InputField value={editData?.email ? editData?.email : ""} onChange = {(e)=>{handleFieldChange(e, "email");}} variant="outlined" label="Email" />
                                    {
                                        showValidation && !editData?.email ? 
                                            <Typography color="error">{t("manage_csr.enter_email")}</Typography>
                                            : null
                                    }
                                </Stack>
                                <Box className="wpx_modal_btnCenter">
                                    <Button
                                        className="wpx_save__btn"
                                        variant="contained"
                                        startIcon={<SaveAsIcon />}
                                        label={t("common.save")}
                                        onClick={handleAddCSR}
                                    />
                                </Box>
                            </Box>
                        </DialogContentText>
                    </DialogContent>
                </DialogContent>
            </DialogModal>
        </>
    );
};

export default ManageCSRList;
