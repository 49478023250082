import React from "react";
import { Card, CardContent, Typography, Box, IconButton } from "@mui/material";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import Modal from "../../common/modal/Modal";
import Grid from "@mui/material/Grid2";
import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";
import ThumbDownOffAltIcon from "@mui/icons-material/ThumbDownOffAlt";


/**
 * TODO: Show corect data for the Insight, curently using static content for stubbing purpose
 * @param props : Required: handleInsightModel Function - This can be used to close the model when needed
 *                          isInsightOpen - boolean type - This will be used to show the model if true
 * @returns Insight for the chat conversation
 */
const Insight = (props: { handleInsightModel: () => void, isInsightOpen: boolean }) => {

    const { handleInsightModel, isInsightOpen } = props;

    const handleLike = () => {
        console.log("insight liked");
    };

    const handleDisike = () => {
        console.log("insight disliked");
    };

    return (
        <Modal open={isInsightOpen}>
            <Box
                className="wpx_box_insight"
            >
                <IconButton
                    aria-label="close"
                    onClick={handleInsightModel}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: "black",
                    }}
                >
                    <HighlightOffIcon />
                </IconButton>

                <Card sx={{ padding: "10px", minHeight: "50vh" }} className="wpx_chat_bg_color">
                    <Typography variant="h6" gutterBottom mt={2} className="wpx_font_insight">
                        Session
                    </Typography>
                    <CardContent sx={{ maxHeight: "60vh", overflowY: "scroll" }}>
                        <Grid container spacing={5}>
                            {/* Left Column */}
                            <Grid size={{ xs: 12, md: 7 }} >
                                <Typography variant="h6" className="wpx_font_insight">Session Summary</Typography>
                                <Typography className="wpx_font_insight">
                                    Ramesh
                                    <br />
                                    40, Male
                                    <br />
                                    2024-07-18 22:42
                                    <br />
                                    #33301928-9873
                                </Typography>

                                <Typography className="wpx_font_insight">Seeking a second opinion for chest pain.</Typography>

                                <Typography variant="h6" className="wpx_font_insight">History</Typography>
                                <Typography variant="body1" gutterBottom className="wpx_font_insight">
                                    The patient experiences chest pain 2-3 times a week, which has increased to 4-5 times recently.
                                    The pain is triggered by exercise, stress, climbing stairs, and cold weather. It has also woken
                                    him up at night, although infrequently. The pain’s frequency and duration have increased over
                                    the past few weeks. The patient has felt lightheaded during severe episodes but reports no fainting,
                                    palpitations, or swelling in the legs or feet. No recent infections or illnesses were noted.
                                    The patient has been eating healthier and reduced exercise to avoid triggering chest pain, with no
                                    significant impact on symptoms.
                                </Typography>

                                <Typography variant="h6" gutterBottom className="wpx_font_insight">
                                    Test Results
                                </Typography>
                                <Typography variant="body1" gutterBottom className="wpx_font_insight">
                                    No recent blood tests for CRP or ESR. Previous tests did not include these markers of inflammation.
                                    A repeat ECG and possibly a coronary angiography have been recommended.
                                </Typography>

                                <Typography variant="h6" gutterBottom className="wpx_font_insight">
                                    Diagnosis/Recommendation
                                </Typography>
                                <Typography variant="body1" gutterBottom className="wpx_font_insight">
                                    The doctor suspects a possible progression of coronary artery disease or new blockages, given
                                    the increased frequency and duration of chest pain. The patient has been advised to undergo a
                                    repeat ECG and possibly a coronary angiography to assess the condition further. The patient has
                                    been recommended to continue with a healthier diet and may need to adjust exercise habits based
                                    on further evaluation. Monitoring for any new symptoms or changes in the current symptoms is
                                    also suggested.
                                </Typography>

                                <Typography variant="h6" gutterBottom className="wpx_font_insight">
                                    Referrals
                                </Typography>
                                <Typography variant="body1" gutterBottom className="wpx_font_insight">
                                    2024-07-18 22:36 - Cardiologist
                                </Typography>
                            </Grid>

                            {/* Vertical Line */}
                            <Grid size={{ md: 1 }}>
                                <Box sx={{ borderLeft: "2px solid black", minHeight: "60vh", mx: 2 }}></Box>
                            </Grid>

                            {/* Right Column */}
                            <Grid size={{ xs: 12, md: 4 }}>
                                <Typography variant="h6" gutterBottom className="wpx_font_insight">
                                    Diagnosis
                                </Typography>
                                <Typography className="wpx_font_insight">
                                    Have you been prescribed any medication for cholesterol management or been advised to take
                                    aspirin or any other antiplatelet agents? Please proceed with scheduling the repeat ECG and the
                                    coronary angiography as discussed.
                                </Typography>
                                <Typography className="wpx_font_insight">
                                    These tests will help us assess the current status of your coronary arteries and the function
                                    of your heart more accurately. Additionally, I would like to order blood tests for C-reactive
                                    protein (CRP) and erythrocyte sedimentation rate (ESR) to check for any signs of inflammation
                                    that may be contributing to your symptoms.
                                </Typography>
                                <Typography className="wpx_font_insight">
                                    In the meantime, it’s important to continue with your healthier diet and consider incorporating
                                    light to moderate exercise that does not trigger your symptoms, such as walking or swimming, as tolerated.
                                </Typography>
                                <Typography className="wpx_font_insight">
                                    Exercise can help improve cardiovascular health and may help manage your symptoms in the long term.
                                    It’s also important to manage stress, as it can exacerbate chest pain and other symptoms. Techniques
                                    such as deep breathing exercises, meditation, or speaking with a therapist may be beneficial.
                                    Please keep me updated with the results of the tests, and we can discuss the next steps in your
                                    management plan based on those findings.
                                </Typography>
                                <Typography variant="caption" display="block" className="wpx_font_insight">
                                    *Generated by AI assistant. To be used strictly as guidance and NOT as a recommendation for any
                                    diagnostic or therapeutic approach.
                                </Typography>
                            </Grid>
                        </Grid>
                    </CardContent>
                    <Box >
                        <IconButton
                            sx={{ fontSize: "large" }}
                            onClick={handleLike}
                        >
                            <ThumbUpOffAltIcon />
                        </IconButton>
                        <IconButton
                            onClick={handleDisike}
                        >
                            <ThumbDownOffAltIcon />
                        </IconButton>
                    </Box>
                </Card>
            </Box>

        </Modal>
    );
};

export default Insight;