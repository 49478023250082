import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import CommonType, { commonInitialValue } from "../../interfaces/CommonInterface";

const initialState: CommonType = commonInitialValue;

const commonSlice = createSlice({
    name: "common",
    initialState,
    reducers: {
        USER_ROLE: (state, action: PayloadAction<number | null>) => {
            state.userRole = action.payload;
        },
        IS_LOGIN: (state, action: PayloadAction<boolean>) => {
            state.isLogin = action.payload;
        },
        IS_SNACKBAR: (state, action: PayloadAction<boolean>) => {
            state.isSnackbar = action.payload;
        },
        SNACKBAR_MESSAGE: (state, action: PayloadAction<string>) => {
            state.snackbarMessage = action.payload;
        },
        USER_DETAILS: (state, action: PayloadAction<any>) => {
            state.userDetails = action.payload;
        },         
    }   
});

export default commonSlice.reducer;
export const commonActions = commonSlice.actions;