import React, { useEffect } from "react";
import { Container } from "@mui/material";
import useAxios from "../../hooks/useAxios";
import { mockAPIPath } from "./config/apiPath";
import MultipleOrganization from "./pages/MultipleOrganization";
import NoOrganization from "./pages/NoOrganization";
import ManageMedProfList from "../OA/pages/MedProf";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { commonActions } from "../../store/features/commonSlice";
import { CONSTANTS } from "../../constants/constants";
import ActiveSessionList from "../CSR/pages/ActiveSessionList";

const OrganizationSelection = () => {
    
    const userRole = useAppSelector((state) => state.common.userRole);
    const dispatch = useAppDispatch();
    const { data } = useAxios({
        url: mockAPIPath.ORGANIZATION_LIST,
        method: "GET",
    });

    useEffect(()=>{
        if(data?.data?.length === 1 ){
            dispatch(commonActions.IS_LOGIN(true));
        }
    },[data, dispatch]);

    return (
        <>
            {data?.data?.length === 1 ? (
                userRole === CONSTANTS.ORG_ADMIN ?
                    <ManageMedProfList /> :
                    <ActiveSessionList/>
            ) : (
                <Container className="wpx_organizationSelection">
                    {data?.data?.length ? (
                        <MultipleOrganization organizationList={data?.data} />
                    ) : (
                        <NoOrganization />
                    )}
                </Container>
            )}
        </>
    );
};

export default OrganizationSelection;
