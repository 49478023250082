import { useState, useEffect } from "react";
import axios, { AxiosRequestConfig, AxiosError } from "axios";

type UseAxiosProps = {
  url: string;
  method: string;
  payload?: any; 
}

const useAxios = ({
    url,
    method,
    payload = null, 
}: UseAxiosProps) => {
    const [data, setData] = useState<any>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const fetchData = async (url: string, method: string, payload?: any) => {
        setLoading(true);
        setError(null);
        const config: AxiosRequestConfig = {
            method: method,
            data: payload,
        };
        try {
            const response = await axios(url, config);
            setData(response.data);
           
        } catch (err) {
            const axiosError = err as AxiosError;
            setError(axiosError.message);
           
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData(url, method, payload);       
    }, [url, method, payload]);

    return { fetch: (url:string, method:string, payload?: any) => fetchData(url, method, payload), data, loading, error };
};

export default useAxios;
