import React, { useEffect, useState } from "react";
import useAxios from "../../../hooks/useAxios";
import Table from "../../../components/common/table/Table";
import { Box, IconButton, Typography } from "@mui/material";
import { mockAPIPath } from "../config/apiPath";
import Button from "../../../components/common/button/Button";
import { useTranslation } from "react-i18next";
import ChatSession from "../../../components/chatSession";
import { useAppSelector } from "../../../store/hooks";
import { specialityListType } from "../../../types/CommonType";
import Insight from "../../../components/chatSession/insight";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../constants/routes";
import CoPresentIcon from "@mui/icons-material/CoPresent";
import Skeleton from "../../../components/common/skeleton";
import SummarizeIcon from "@mui/icons-material/Summarize";
import ChatIcon from "@mui/icons-material/Chat";

const UserChatHistoryList = () => {
    const [t] = useTranslation();
    const navigate = useNavigate();

    const userDetails = useAppSelector((state) => state.common.userDetails);

    const [specialityList, setSpecialtyList] = useState<specialityListType>([]);
    const [chatHistoryList, setChatHistoryList] = useState<any>([]);
    const [selectedChatHistory, setSelectedChatHistory] = useState<any>(null);
    const [startConversation, setStartConversation] = useState<boolean>(false);
    const [isChatScreenOpen, setIsChatScreenOpen] = useState<boolean>(false);
    const userRole = useAppSelector((state) => state.common.userRole);
    const [isInsightOpen, setIsInsightOpen] = useState<boolean>(false);
    const [skeleton, setSkeleton] = useState<any>(true);


    const userChatHistoryApiCall = useAxios({
        url: `${mockAPIPath.USER_CHAT_HISTORY_LIST}`,
        method: "GET",
    });

    useEffect(() => {
        const { data } = userChatHistoryApiCall;
        if (data) {
            setChatHistoryList(data.data);
        }
        setSkeleton(false);
    }, [userChatHistoryApiCall]);

    const { data: specialityListApiData } = useAxios({
        url: `${mockAPIPath.SPECIALITY_LIST}`,
        method: "GET",
    });

    useEffect(() => {
        setSpecialtyList(specialityListApiData?.data.filter(((item: any) => item.specialty !== userDetails?.speciality)));
    }, [specialityListApiData, userDetails?.speciality]);


    const handleClickSpecialist = () => {
        setStartConversation(true);
    };


    // const tableData =
    //     chatHistoryList?.map((item: any) => {
    //         return {
    //             id: item.id,
    //             date: item.date,
    //             organizationName: item.organizationName,
    //             reasonForVisit: item.reasonForVisit,
    //             doctorName: item.doctorName,
    //             chatCompleted: item.chatCompleted,
    //             subHistory: item.subHistory,
    //         };
    //     }) ?? [];

    const columns = [
        { id: "date", label: t("user_chat_history.date"), width: "10%" },
        { id: "organizationName", label: t("user_chat_history.organization_name"), width: "20%" },
        { id: "reasonForVisit", label: t("user_chat_history.reason_for_visit"), width: "50%" },
        { id: "doctorName", label: t("user_chat_history.doctor_name"), width: "20%" },
        { id: "actions", label: t("common.action"), width: "10%" },
    ];

    const handleReportClick = () => {
        setIsInsightOpen(true);
    };

    const handleSecondOpinion = () => {
        setIsChatScreenOpen(true);
        // setDirectChat(true);
    };

    const handleContinueChat = () => {
        setIsChatScreenOpen(true);
    };

    const handleInsightModel = () => {
        setIsInsightOpen(false);
    };

    const handleClickReferMP = () => {
        navigate(ROUTES.MP.REFER_SPECIALIST);
    };

    const handleCustomAction = (rowData: any) => {
        return (
            rowData?.chatCompleted ?  <IconButton  onClick ={() => handleReportClick()} ><SummarizeIcon /> </IconButton> : <ChatIcon onClick ={() => handleContinueChat()}/>
        );
    };

    return (
        <>
            <Box sx ={{mr:2}}>
                <Box sx={{ mt: 5, ml: 3 }}>
                    <Typography> Rahul Mishra</Typography>
                    <Typography> Male, 40 years old</Typography>
                </Box>
                <Box sx={{ float: "right", mb: 3 }}>
                    <Button
                        className="wpx_save__btn"
                        variant="contained"
                        label="Ask to Specialist"
                        onClick={handleClickSpecialist}
                        disabled={!selectedChatHistory}
                    />
                    <Button
                        className="wpx_save__btn"
                        variant="contained"
                        label="Second Opinion"
                        onClick={handleSecondOpinion}
                        disabled={!selectedChatHistory}
                    />
                    <Button
                        className="wpx_save__btn"
                        variant="contained"
                        label="Refer to another MP"
                        startIcon={<CoPresentIcon />}
                        onClick={handleClickReferMP}
                        disabled={!selectedChatHistory}
                    />
                </Box>

                {skeleton ? (
                    <Skeleton
                        variant="rectangular"
                        width={"100%"}
                        height={30}
                        count={15}
                    />
                ) : ( 
                    <Table
                        data={chatHistoryList}
                        columns={columns}
                        requireCheckbox={true}
                        setSelectedChatHistory={setSelectedChatHistory}
                        handleForward={handleReportClick}
                        handleCustomAction={handleCustomAction}
                    />
                )}
            </Box>

            <ChatSession
                startConversation={startConversation}
                setStartConversation={setStartConversation}
                specialityList={specialityList}
                userRole={userRole}
                isChatScreenOpen={isChatScreenOpen}
                setIsChatScreenOpen={setIsChatScreenOpen}
            />         
            <Insight
                handleInsightModel={handleInsightModel}
                isInsightOpen={isInsightOpen}
            />
        </>
    );
};

export default UserChatHistoryList;
