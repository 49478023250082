import React, { ReactNode } from "react";
import BasicModal from "@mui/material/Modal";
import { Box } from "@mui/material";

type DataModalProps = {
    open: boolean;
    onClose?: () => void;
    children: ReactNode
    className?: string;
};

const Modal = (props:DataModalProps) => {

    const {children, open, onClose} = props;

    return (
        <>
            <BasicModal
                open={open}
                onClose={onClose}
                className="wpx_modal"
                aria-labelledby="modal-modal-title"
                aria-describedby="parent-modal-description"
            >
                <Box className="wpx_modal">
                    {children}
                </Box>
            </BasicModal>
        </>
    );
};

export default Modal;
