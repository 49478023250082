import React, { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import Modal from "../../common/modal/Modal";
import { useTranslation } from "react-i18next";
import Button from "../../common/button/Button";
import Grid from "@mui/material/Grid2";
import {
    Box,
    Typography,
    FormControl,
    RadioGroup,
    FormControlLabel,
    Radio,
    IconButton
} from "@mui/material";
import InputField from "../../common/input/InputField";


const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "55%",
    bgcolor: "background.paper",
    boxShadow: 15,
    p: 3,
    borderRadius: "10px !important"
};
/**
 * Provide option to choose for the reason for the discard of the Chat conversation before completion
 *
 * @returns Feedback Modal with Reason selection feature
 */
const FeedbackModal = (props: any) => {
    const { t } = useTranslation();

    const [reason, setReason] = useState("");
    const [otherReason, setOtherReason] = useState("");
    const { isFeedbackOpen, setIsChatScreenOpen, setIsFeedbackOpen, setErrorMessage, errorMessage } = props;

    const handleReasonChange = (event: { target: { value: React.SetStateAction<string>; }; }) => {
        setReason(event.target.value);
        setErrorMessage(null);
    };

    const feedbackArray: string[] = ["Discomfort or Fatigue", "Confusion or Misunderstanding", "Privacy Concerns", "Distrust or Suspicion", "Technical Issues", "Urgent Matter or Distraction", "Emotional Distress", "Length of the Session",  "Perceived Lack of Empathy", "Other" ];


    const handleFeedbackSubmit = () => {
        if (reason === "Other" && !otherReason) {
            setErrorMessage("Please provide a reason...");
        } else if (reason) {
            setIsFeedbackOpen(false);
        } else {
            setErrorMessage("Please select any one of the above");
        }
    };

    const handleOtherReasonChange = (event: { target: { value: React.SetStateAction<string>; }; }) => {
        setOtherReason(event.target.value);
        setErrorMessage(null);
    };

    const handleClose = () => {
        setIsFeedbackOpen(false);
        setIsChatScreenOpen(true);
        setErrorMessage(null);
    };

    const handleBackSesssion = () => {
        setIsFeedbackOpen(false);
        setIsChatScreenOpen(true);
    };


    return (
        <Modal open={isFeedbackOpen}>
            <Box sx={style}>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Typography variant="h6" component="h2" sx={{ fontSize: "18px" }}>
                        {t("patient_walked_away.patient_walked")}
                    </Typography>
                    <IconButton onClick={handleClose} sx={{ color: "black" }}>
                        <CloseIcon />
                    </IconButton>
                </Box>
                <Typography sx={{ fontSize: "14px" }}>
                    {t("patient_walked_away.relevant_reason")}
                </Typography>
                <FormControl className="wpx_formControl" component="fieldset" sx={{ mt: 1 }}>
                    <RadioGroup value={reason} onChange={handleReasonChange} color="#000000">
                        {feedbackArray.map((feedbackOptions: string, feedbackCount: number) => (
                            <FormControlLabel key={feedbackCount} className="textsize_for_formcontrollabel wpx_formControl__label" value={feedbackOptions}
                                control={<Radio className="wpx_formControl__radioBtn" sx={{ "&.Mui-checked": { color: "#000000", }, }} />} label={feedbackOptions} sx={{ marginBottom: 0}}/>
                        ))}
                    </RadioGroup>
                    {reason === "Other" && (
                        <InputField
                            variant="outlined"
                            value={otherReason}
                            placeHolder={t("patient_walked_away.other_reason")}
                            onChange={handleOtherReasonChange}
                            className="wpx_other_reason_style"
                        />
                    )}
                </FormControl>
                <Typography color="error" sx={{ mt: 1 }}>
                    {errorMessage}
                </Typography>
                <Grid container spacing={2}>
                    <Button
                        variant="contained"
                        onClick={handleFeedbackSubmit}
                        label={t("patient_walked_away.submit")}
                        className="wpx_next__btn"
                    />
                    <Button
                        variant="contained"
                        onClick={handleBackSesssion}
                        label="Back to Session"
                        className="wpx_next__btn"
                    />
                </Grid>
            </Box>
        </Modal>
    );
};

export default FeedbackModal;