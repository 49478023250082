import { Skeleton, SxProps, Theme } from "@mui/material";
import { OverridableStringUnion } from "@mui/types";

import React from "react";
type skeletonProps = {
    variant?: OverridableStringUnion<"text" | "rectangular" | "rounded" | "circular">;
    height?: string | number;
    count: number;
    my?: SxProps<Theme>;
    width?: number | string;
}

/**
 * Skeleton use to display a placeholder preview of your content before the data gets loaded
 * 
 * @param props: skeletonProps : 
 *  variant - "rectangular" | "text" | "rounded" | "circular" (The component supports 4 shape variants)
 *  height?: string | number; (The custom height of placeholder )
 *  count: number; (The number of placeholder you want must be number - used for creating array to itetrate for creating mui skeleton)
 *  my?: SxProps<Theme>; (Custom styling for placeholder) 
 *  width?: number | (string The custom width of placeholder)
 * @returns Skeleton Placeholder with given skeletonProps
 */
function BasicSkeleton ( props: skeletonProps ) {
    const {variant, height, width, count, my} = props;
    const countArray = Array.from({length: count}, (_, i) => i + 1);
    return (
        <>
            {
                countArray.map((index: number)=>(
                    <><Skeleton key={index} variant={variant} width={width} height={height} sx={my} /><br /></>
                ))
            }
        </>
    );
}
 
export default BasicSkeleton;