import React, { useState } from "react";
import { Container } from "@mui/material";
import Home from "./pages/Home";
import RoleSelection from "./pages/RoleSelection";
import SocialAccountSelection from "./pages/SocialAccountSelection";

const Login = () => {

    const [userRole, setUserRole] = useState <number> (0);

    return(
        <Container className = "wpx_centeredContainer">             
            <Home/>
            <RoleSelection userRole={userRole} setUserRole={setUserRole} />  
            {userRole ? 
                <SocialAccountSelection/>              
                :
                null}                
        </Container>
    );
};

export default Login;